import React, { useContext, createContext, useState } from 'react'

const FullscreenContext = createContext<{
  isFullscreen: boolean
  setIsFullscreen: (isFullscreen: boolean) => void
} | null>(null)

function FullscreenProvider(props: { children: React.ReactNode }) {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const value = { isFullscreen, setIsFullscreen }

  return (
    <FullscreenContext.Provider value={value}>
      {props.children}
    </FullscreenContext.Provider>
  )
}

function useFullscreen() {
  const ctx = useContext(FullscreenContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { FullscreenProvider, useFullscreen }
