export const startRecording = async (
    task,
    chunksRef,
    audioStreamRef,
    mediaRecorderRef,
    setIsRecording,
    setIsSaving,
    setIsSaved,
    setAudioURL,
    setTotalDuration,
    setTimer,
    dispatch,
    handleStop1,
    handleRecord1,
    setIsStopped,
    startTime,
    stopTime,
    QUESTION_ACTIONS
) => {

  
  if (task.isSkipped) {
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }

    task.audioRecordings = []
    setIsStopped(false)
    setIsRecording(true)
    handleRecord1()
    //add this class to body to disable click outside the record button
    document.body.classList.add('click-disabled')

    try {
      startTime = Date.now()
      chunksRef.current = []

      audioStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      mediaRecorderRef.current = new MediaRecorder(audioStreamRef.current)

      mediaRecorderRef.current.ondataavailable = event => {
        chunksRef.current.push(event.data)
      }

      mediaRecorderRef.current.onstop = async () => {
        stopTime = Date.now()
        const audioBlob = new Blob(chunksRef.current, { type: 'audio/mpeg' });

        const wavBlob = await convertToWav(audioBlob);
        setAudioURL(URL.createObjectURL(audioBlob))
        const durationInSeconds = Math.ceil((stopTime - startTime) / 1000) // Calculate duration in seconds
        setTotalDuration(durationInSeconds)
        let durationFormatted
        if (durationInSeconds >= 60) {
          const minutes = Math.floor(durationInSeconds / 60)
          const seconds = Math.floor(durationInSeconds % 60)
          durationFormatted = `${minutes}:${seconds
            .toString()
            .padStart(2, '0')}` // Format as "minutes:seconds"
        } else {
          durationFormatted = durationInSeconds.toString() // Format as seconds if less than one minute
        }
        const audioObject = {
          audioLength: durationFormatted,
          audioFile: wavBlob,
          taskId: task.id,
          isSkipped: task.isSkipped,
          skipReason : sessionStorage.getItem(task.id+'-language') ?? undefined
        }

        task.audioRecordings.push(audioObject)

        setIsRecording(false)

        //remove this class to body to enable click outside the record button
        document.body.classList.remove('click-disabled')

        setIsStopped(true)
      }

      mediaRecorderRef.current.start()
      setIsRecording(true)
      setIsSaving(false) // Reset saving state when record button is clicked
      setIsSaved(false) // Reset saved state when record button is clicked
    } catch (error) {
      console.error('Error accessing microphone:', error)
    }
  }


// Function to convert Blob to WAV format with specified properties
const convertToWav = async (blob) => {
  const audioContext = new AudioContext();
  const audioBuffer = await blob.arrayBuffer();
  const audioData = await audioContext.decodeAudioData(audioBuffer);

  const offlineContext = new OfflineAudioContext({
    numberOfChannels: 1, // Mono channel
    length: audioData.length,
    sampleRate: 16000, // 16 kHz sample rate
  });

  const source = offlineContext.createBufferSource();
  source.buffer = audioData;

  source.connect(offlineContext.destination);
  source.start();

  const renderedBuffer = await offlineContext.startRendering();

  // Convert to 16-bit PCM
  const wavBlob = encodeWAV(renderedBuffer);

  return wavBlob;
};

// Function to encode WAV format
const encodeWAV = (audioBuffer) => {
  const numChannels = 1; // Mono channel
  const sampleRate = 16000; // 16 kHz sample rate
  const bytesPerSample = 2; // 16-bit samples
  const buffer = audioBuffer.getChannelData(0);
  const dataSize = buffer.length * bytesPerSample;

  const bufferLength = dataSize + 44;
  const arrayBuffer = new ArrayBuffer(bufferLength);
  const view = new DataView(arrayBuffer);

  // Write WAV header
  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + dataSize, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true); // PCM format
  view.setUint16(22, numChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, sampleRate * numChannels * bytesPerSample, true);
  view.setUint16(32, numChannels * bytesPerSample, true);
  view.setUint16(34, bytesPerSample * 8, true);
  writeString(view, 36, 'data');
  view.setUint32(40, dataSize, true);

  // Write PCM data
  floatTo16BitPCM(view, 44, buffer);

  return new Blob([arrayBuffer], { type: 'audio/wav' });
};

const writeString = (view, offset, str) => {
  for (let i = 0; i < str.length; i++) {
    view.setUint8(offset + i, str.charCodeAt(i));
  }
};

const floatTo16BitPCM = (output, offset, input) => {
  for (let i = 0; i < input.length; i++, offset += 2) {
    const sample = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
  }
};

  export const stopRecording = (
    handleStop1,
    mediaRecorderRef,
    audioStreamRef,
    setIsRecording,
    setIsSaving,
    setTimer,
    dispatch,
    QUESTION_ACTIONS,
    setIsSaved
  ) => {
    handleStop1()
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== 'inactive'
    ) {
      mediaRecorderRef.current.stop()
      audioStreamRef.current?.getTracks().forEach(track => track.stop())
      setIsRecording(false)
      setIsSaving(true)

      setTimer({ minutes: 3, seconds: 0 })
      dispatch({
        type: QUESTION_ACTIONS.UPDATE_LANGUAGE_ANALYSIS_TASK,
      })
      document.body.classList.remove('click-disabled')

      setTimeout(() => {
        setIsSaving(false)
        setIsSaved(true)
      }, 1000)
    }
  }

  export const playAudio = (
    setIsPlaying,
    audioURL,
    audioRef,
    setAudioProgress,
    requestAnimationFrame
  ) => {
    setIsPlaying(true);
    if (audioURL && audioRef.current) {
      const audioElement = audioRef.current;
      
      
      const updateProgress = () => {
        const progressValue =
          (audioElement.currentTime / audioElement.duration) * 100;
        
        setAudioProgress(progressValue);
        if (!audioElement.paused) {
          requestAnimationFrame(updateProgress);
        }
      };
      updateProgress()
      requestAnimationFrame(updateProgress);
      audioElement.load()
      audioElement.play();
      audioElement.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    }
  };

  

  export const audioProgressBar = (audioRef, setAudioProgress) => {
    if (audioRef.current) {
      const progressValue =
        (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setAudioProgress(progressValue);
    }
  };

  export const pauseAudio = (
    setIsPlaying,
    audioURL,
    audioRef
  ) => {
    setIsPlaying(false)
    if (audioURL && audioRef.current) {
      const audioElement = audioRef.current
      audioElement.pause()
    }
  }
