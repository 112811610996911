import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToast } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FeedbackShape, FeedbackType } from 'data-models'
import { feedbackSchema } from '../schema'
import { sendFeeback } from 'services/feedback'
import { useTranslation } from 'react-i18next'
const defaultValues: FeedbackShape = {
  feedback: '',
  type: FeedbackType.REPORT_ISSUE,
}

interface UseFeedbackModalProps {
  onClose: () => void
}

export const useFeedbackModal = ({ onClose }: UseFeedbackModalProps) => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const formMethods = useForm<FeedbackShape>({
    defaultValues,
    resolver: yupResolver(feedbackSchema),
  })
  const { t } = useTranslation()
  const submitHandler = async (values: FeedbackShape) => {
    try {
      setIsLoading(true)
   
      await sendFeeback({
        ...values,
      })

      toast({
        title: `${t('Feedback_Sent_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })

      onClose()
    } catch (error) {
      toast({
        title: `
        ${t('Feedback_Failed_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = formMethods.handleSubmit(submitHandler)

  return { formMethods, onSubmit, isLoading }
}
