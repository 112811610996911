import { Box, IconButton, Progress, VStack, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { FaPause, FaPlay } from 'react-icons/fa'

interface AudioPlayerLanguageProps {
  isPlaying: boolean
  handlePauseClick: () => void
  handlePlayClick: () => void
  audioProgress: number
  audioRef: React.RefObject<HTMLAudioElement>
  formatTime: (timeInSeconds: number) => string
  audioURL: string | null
  handleAudioProgress: () => void
}

const AudioPlayerLanguage: React.FC<AudioPlayerLanguageProps> = ({
  isPlaying,
  handlePauseClick,
  handlePlayClick,
  audioProgress,
  audioRef,
  formatTime,
  audioURL,
  handleAudioProgress,
}: AudioPlayerLanguageProps) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  return (
    <VStack width='70%'>
      <Box display='flex' width='100%' flexDirection='row' alignItems='center' justifyContent='center'>
        {isPlaying ? (
          <IconButton
            aria-label='play-btn'
            icon={<FaPause />}
            boxSize='40px'
            borderRadius='full'
            onClick={handlePauseClick}
            marginRight='10px'
          />
        ) : (
          <IconButton
            aria-label='play-btn'
            icon={<FaPlay />}
            boxSize='40px'
            borderRadius='full'
            onClick={handlePlayClick}
            marginRight='10px'
          />
        )}

        <Progress
          value={audioProgress}
          size='xs'
          colorScheme='pink'
          width={isMobile ? '60%' : '80%'} 
        />
        <Text ml={2}>
          {audioRef.current ? formatTime(audioRef.current.currentTime) : '0:00'}
        </Text>
        <audio
          ref={audioRef}
          src={audioURL ?? undefined}
          controls
          onTimeUpdate={handleAudioProgress}
          onPlay={handleAudioProgress}
          style={{ position: 'absolute', left: '-9999px' }}
        />
      </Box>
    </VStack>
  )
}

export default AudioPlayerLanguage
