import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  MenuButton,
  MenuItem,
  MenuList,
  SelectProps,
  Button,
  Menu,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import GlobeWhite from '../assets/images/GlobeWhite.svg'
import GlobeBlack from '../assets/images/GlobeBlack.png'
interface LanguageSwitcherProps extends SelectProps {
  languages: string[]
  textColor: string
  bgColor: string
  bgDrop: string
  DropactiveColor: string
  globColor: boolean
  activeColor: string
  borderB: boolean
}
function LanguageSwitcher({
  languages,
  textColor,
  bgColor,
  bgDrop,
  globColor,
  activeColor,
  DropactiveColor,
  borderB,
}: LanguageSwitcherProps) {
  const { i18n } = useTranslation()
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language-selected',i18n.language)
  }

   
 

  return (
    <Menu>
      <>
        <MenuButton
          as={Button}
         // rightIcon={<ChevronDownIcon />}
          leftIcon={
            globColor ? (
              <img src={GlobeWhite} style={{ width: '20px' }} alt='' />
            ) : (
              <img src={GlobeBlack} style={{ width: '24px' }} alt='' />
            )
          }
          colorScheme='custom'
          bg={bgColor}
          borderColor={borderB ? 'white' : ''} // Set border color to white
          borderWidth={borderB ? '1px' : '0px'} // Set border width
          // border='white'
          color={textColor}
          boxShadow='none'
          width='75px'
          height='45px'
          _hover={{ bg: activeColor }}
          _active={{ bg: activeColor }}
          size='sm'
          fontSize='15px'
          margin='0px'
        >
          {i18n.language.toUpperCase()}
        </MenuButton>
        <MenuList w={3} p={0} minW='110' borderWidth={0} width={'100px'}>
          {languages.map(language => (
            <MenuItem
              color='white'
              border-width='0px'
              bg={bgDrop}
              p={3}
              _focus={{ bg: DropactiveColor }}
              _hover={{ bg: DropactiveColor }}
              key={language}
              onClick={() => changeLanguage(language)}
            >
              {language === 'en' ? 'English' : language === 'se' ? 'Svenska' : language === 'de' ? 'German' : 'English'}
            </MenuItem>
          ))}
        </MenuList>
      </>
    </Menu>
  )
}

export default LanguageSwitcher
