import React, { PropsWithChildren } from 'react'
import { useRadio, UseRadioProps, HStack, Flex, Text } from '@chakra-ui/react'

interface CustomRadioButtonProps extends PropsWithChildren<UseRadioProps> {
  label: string
}

export const CustomRadioButton = (props: CustomRadioButtonProps) => {
  const { getInputProps, getCheckboxProps, state } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <HStack justify='center' align='center' as='label'>
      <input {...input} />
      <Flex
        {...checkbox}
        justify='center'
        align='center'
        cursor='pointer'
        borderWidth='1px'
        borderRadius='full'
        boxShadow='md'
        _checked={{
          bg: 'darkIndigo',
          color: 'white',
          borderColor: 'darkIndigo',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        h='24px'
        w='24px'
        margin={'0 !important'}
      >
        {state.isChecked && props.children}
      </Flex>
      <Text color={'black'}>{props.label}</Text>
    </HStack>
  )
}
