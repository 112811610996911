import React from 'react'
import { HStack } from '@chakra-ui/react'
import Dropdown from 'react-dropdown'

export default function PreschoolDropdown(props: {
  preschoolOptions: any
  onPreschoolSelect: any
  preschool: any
  placeHolder: string
  departmentId: string
  
}) {
  return (
    <HStack spacing='5' w='fit-content'>
      <Dropdown
        options={props.preschoolOptions}
        onChange={props.onPreschoolSelect}
        value={props.preschool.id && props.departmentId ? props.preschool.name : ''}
        placeholder={props.placeHolder}
        controlClassName={`custom-control w-preschool-department ${props.preschool.id && props.departmentId ? '' : 'addError'}`}
        arrowClassName='custom-arrow'
        menuClassName='custom-menu'
        placeholderClassName='custom-placeholder'
      />
    </HStack>
  )
}
