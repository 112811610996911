import React, { useContext, createContext, useState, useEffect } from 'react'
import { emptyPreschool, Preschool } from 'data-models/preschool'
import { PreschoolService } from 'services/preschoolService'
import { useUser } from './UserProvider'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
const PreschoolContext = createContext<{
  preschool: Preschool
  setPreschool: (preschool: Preschool) => void
  departmentId: string
  setDepartmentId: (newVal: string) => void
  preschools: Preschool[]
  setPreschools: (preschools: Preschool[]) => void
  loading: boolean
} | null>(null)

function PreschoolProvider(props: { children: React.ReactNode }) {
  const cachedPreschool = PreschoolService.getCachedPreschool()
  const { t } = useTranslation()
  const [preschool, setPreschool] = useState(
    cachedPreschool?.id
      ? PreschoolService.getCachedPreschool()
      : emptyPreschool,
  )
  const [departmentId, setDepartmentId] = useState<string>('')
  const [preschools, setPreschools] = useState([] as Preschool[])
  const [loading, setIsloading] = useState(false)

  const { user } = useUser()
  const toast = useToast()

  const value = {
    preschool,
    setPreschool,
    preschools,
    setPreschools,
    loading,
    departmentId,
    setDepartmentId,
  }

  useEffect(() => {
    setIsloading(true)

    if (user) {
      PreschoolService.getPreschools(user.organizationId)
        .then(setPreschools)
        .catch(err => {
          toast({
            title: `${t(
              'No_Linked_Preschools_Found_Text',
            )}`,
            position: 'bottom-right',
            status: 'error',
          })
        })
        .finally(() => setIsloading(false))
    }
  }, [user.email])

  useEffect(() => {
    PreschoolService.cachePreschool(preschool)
  }, [preschool])

  return (
    <PreschoolContext.Provider value={value}>
      {props.children}
    </PreschoolContext.Provider>
  )
}

function usePreschool() {
  const ctx = useContext(PreschoolContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { PreschoolProvider, usePreschool }
