import React, { useEffect, useState } from 'react'
import { Text, Flex } from '@chakra-ui/react'
import bookIcon from '../../../../assets/images/book.png'
import { SectionDivider } from './SideBar.layout'
import { TaskService } from 'services/taskService'
import { useFollowUp } from 'providers/FollowUpProvider'
import { usePreschool } from 'providers/PreschoolProvider'
import { useTranslation } from 'react-i18next'
export const FollowupDetailsSideBar = () => {
  const [gender, setGender] = useState('')
  const { child, tasks } = useFollowUp()
  const { preschool } = usePreschool()
  const { t, i18n } = useTranslation()
  const completedTasksCount = tasks.filter(task =>
    TaskService.taskIsAnswered(task),
  ).length
  useEffect(() => {
    if (child.gender === 'Female') setGender(t('Girl_Text'))
    if (child.gender === 'Male') setGender(t('Boy_Text'))
  }, [i18n.language])

  return (
    <>
      <SectionDivider />
      <div style={{ marginRight: '125px', marginBottom: '-20px' }}>
        <Flex>
          <img
            src={bookIcon}
            style={{ width: '25px', height: '25px', paddingTop: '5px' }}
          />
          <Text
            color='black'
            lineHeight={2}
            marginBottom='1em'
            ml={2}
            fontWeight={600}
            paddingLeft='18px'
            fontSize={14}
          >
            {t('FollowUp_Sidebar_Title')}
          </Text>
        </Flex>
      </div>

      <Text paddingLeft='45px' color='#666666' lineHeight={2}>
        {t('FollowUp_Sidebar_Detail')}
        <span style={{ fontWeight: 'bold', color: '#666666' }}>
          {gender}
          {','}
          {child.age
            ? ` ${t('Age_Text')} ${child.age}`
            : ` ${t('Born_In_Text')} ${child.birthdate} `}
        </span>{' '}
      </Text>
    </>
  )
}
