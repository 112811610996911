import React from 'react'
import { Box, Flex, HStack, VStack } from '@chakra-ui/layout'
import { Image, Text, Heading, useColorModeValue } from '@chakra-ui/react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Logo from 'assets/images/Logo-InDarkBG.svg'
import Circles from 'assets/images/Circles-bg.svg'
import Login from './Login'
import LinkedIn from '../../assets/images/ico-linkedin.svg'
import Insta from '../../assets/images/ico-instagram.svg'
import FB from '../../assets/images/ico-facebook.svg'
import { adminUrl } from 'services/config'
import { LoginAvesta } from './LoginAvesta'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from 'common/LanguageSwitcher'
import { useLocation } from 'react-router-dom'

export default function Unauthenticated() {
  const circlesUrl = `url(${Circles})`
  const languages: string[] = ['se', 'en','de']
  const buttonBgColor = useColorModeValue('#7240fa', '#7240fa')
  const location = useLocation()
  const isInPath = location.pathname !== '/login/sso'

  const { t } = useTranslation()
  return (
    <Box
      h='full'
      bgGradient='linear(to-b, purplishBlue, blue)'
      style={{ overflow: 'hidden' }}
    >
      <HStack alignItems='center' h='100%'>
        <VStack flex='1' h='full' justifyContent='center' position='relative'>
          {isInPath && (
            <>
              <Box
                borderRadius='50%'
                bgColor='darkIndigo'
                borderColor='rgba(255, 255, 255, .3)'
                borderWidth='1px'
                pos='absolute'
                zIndex='0'
                h='150%'
                w='200%'
                right='0'
              ></Box>

              <Box
                maxW='350px'
                lineHeight='1.6'
                pos='relative'
                zIndex='1'
                pb={100}
              >
                <Image
                  src={Logo}
                  alt='Myran logotype'
                  htmlWidth='156px'
                  htmlHeight='125px'
                />

                <Heading
                  as='h1'
                  fontSize='20'
                  fontWeight='semibold'
                  lineHeight='1.6'
                >
                  {t('Login_Title_h1')}
                </Heading>
                <Text mt='7' fontSize='12' fontWeight='light'>
                  {t('Main_Test_LoginPage_TeacherPortal')}
                </Text>
              </Box>
            </>
          )}
        </VStack>

        <Flex
          flex='1'
          justifyContent='center'
          alignItems='center'
          h='full'
          bgImage={circlesUrl}
          bgPosition='right center'
          bgSize='60%'
          bgRepeat='no-repeat'
        >
          <Box maxW='400px' w='full'>
            <Switch>
              <Route path='/login/avesta' exact component={LoginAvesta} />
              <Route path='/login/sso' exact component={LoginAvesta} />
              <Route path='/login' component={Login} />
              {/* <Route
                path='/activate/avesta/:activationToken'
                component={RegisterAvesta}
              /> */}
              {/* <Route
                path='/activate/:activationToken'
                component={CreateUserFromInvite}
              /> */}
              <Route path='/' render={() => <Redirect to='/login' />} />
            </Switch>
          </Box>
        </Flex>
      </HStack>
      {isInPath && (
        <HStack alignItems='center' h='10%'>
          <VStack
            h='full'
            justifyContent='center'
            position='relative'
            width='60%'
            pb={40}
            mr={200}
          >
            <Box lineHeight='1.6' pos='relative' zIndex='1'>
              <Flex width='140px'>
                <Text mt={-1} fontSize='12px' fontWeight='light' color='white'>
                  {t('Follow_Text')}&nbsp;&nbsp;
                </Text>
                <a
                  href='https://www.facebook.com/profile.php?id=100090936694050'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={FB} alt='' />
                </a>
                &nbsp;&nbsp;
                <a
                  href='https://www.instagram.com/myranappen_se/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={Insta} alt='' />
                </a>
                &nbsp;&nbsp;
                <a
                  href='https://www.linkedin.com/in/myran-application-8ab27a259/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={LinkedIn} alt='' />
                </a>
              </Flex>
            </Box>
          </VStack>
          <VStack h='full' justifyContent='center' width='55%' pb={40}>
            <Box mr={100}>
              <Flex>
                <Text fontSize='14px' fontWeight='light' color='white'>
                  <a
                    href='https://myranappen.se/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('Website_Link')}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href={adminUrl} target='_blank' rel='noreferrer'>
                    Admin
                  </a>
                </Text>
              </Flex>
            </Box>
          </VStack>
        </HStack>
      )}

      <Flex
        justifyContent='flex-end'
        alignItems='flex-start'
        pos='absolute'
        top='50px'
        right='63px'
        zIndex='1'
      >
        <LanguageSwitcher
          languages={languages}
          textColor='black'
          bgColor={buttonBgColor}
          globColor={true}
          activeColor='#7653f4'
          bgDrop='#7653f4'
          DropactiveColor='#9e7df4'
          borderB={true}
        />
      </Flex>
    </Box>
  )
}
