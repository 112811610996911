import React, { useEffect } from 'react'
import { HStack } from '@chakra-ui/react'
import Dropdown from 'react-dropdown'
import { useFollowUp } from 'providers/FollowUpProvider'

export default function FollowupDropdown(props: {
  FollowUpOptions: any
  handlechanageId: any
  selectedFollowupId: any
  placeHolder: string
}) {
  const { setChild, dispatch, followupInfo } = useFollowUp()
  const selectedItem = props.FollowUpOptions.find(
    item => item.value === followupInfo.id,
  )
  const handleselect = (e: string) => {
    props.handlechanageId(e)
  }
  useEffect(() => {
    handleselect(followupInfo.id)
  }, [])

  return (
    <HStack spacing='5' className='followup-dropdown'>
      <Dropdown
        options={props.FollowUpOptions}
        onChange={FollowUpOptions => handleselect(FollowUpOptions.value)}
        value={selectedItem ? selectedItem.label : ''}
        placeholder={props.placeHolder}
        controlClassName={`custom-control  ${selectedItem ? '' : 'addError'}`}
        arrowClassName='custom-arrow'
        menuClassName='custom-menu'
        placeholderClassName='custom-placeholder'
      />
    </HStack>
  )
}
