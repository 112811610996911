export enum Gender {
  boy = 'Male',
  girl = 'Female',
}

export enum FollowupStatus {
  active = 'Active',
  completed = 'Completed',
  draft = 'Draft',
}
