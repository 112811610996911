import React from 'react'

interface LogoutIconProps {
  color?: string
  size?: string | number
}

export const LogoutIcon = ({
  color = 'white',
  size = '24px',
}: LogoutIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    color={color}
    fill={'none'}
  >
    <path
      d='M8 4L7 4C6.20435 4 5.44129 4.31607 4.87868 4.87868C4.31607 5.44129 4 6.20435 4 7L4 17C4 17.7956 4.31607 18.5587 4.87868 19.1213C5.44129 19.6839 6.20435 20 7 20L8 20M16 16L20 12M20 12L16 8M20 12L8 12'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
