import { useState, useEffect } from 'react'
import { UseToastOptions } from '@chakra-ui/react'

export const successToast = (msg: string): UseToastOptions => ({
  status: 'success',
  title: msg,
  position: 'bottom-right',
})

export const errorToast = (msg: string): UseToastOptions => ({
  status: 'error',
  title: msg,
  position: 'bottom-right',
})

export const secondsToMMSS = (x: number = 0) =>
  new Date(x * 1000).toISOString().substr(14, 5)

export const checkboxAnswerOption = {
  ChildParticipate: 'childParticipate',
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
