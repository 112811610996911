import React, { useEffect, useState } from 'react'
import { ImageSortingTask } from 'data-models/task-models'
import { Flex, VStack } from '@chakra-ui/react'
import { useFollowUp } from 'providers/FollowUpProvider'
import { TaskAdvice } from '../Common'
import { ImagePlacementImagesContainer } from './ImagePlacementImagesContainer'
import { TaskHeading } from '../Common/TaskHeading'


export const ImageSortingTaskCmp = () => {
  const { currentTask } = useFollowUp()
  const task = currentTask as ImageSortingTask
  const [remainingImages, setRemainingImages] = useState(
    task.images as string[],
  )

  const [draggedItem,setDraggedItem] = useState('')
  const isImagePlaced = (task: ImageSortingTask, img: string) => {
    let found = false
  
  
    // if(task.name[1] === 'Sort shapes'){
   
    //   task.answerOptions.forEach((answer,index) => {
     
    //     if (answer.placedAtPoint !== null && img.includes(answer.placedAtPoint+index))
    //       found = true
    //   })
    // }

    // else{
      task.answerOptions.forEach(answer => {
     
        if (answer.placedAtPoint !== null && img.includes(answer.placedAtPoint))
          found = true
      })
   // }
    
  
    return found
  }
  
 
  useEffect(() => {
    
    const newImages = task.images.map((img: string) =>
      isImagePlaced(task, img) ? '' : img,
    )

    setRemainingImages(newImages)
  }, [currentTask])


  
  return (
    <VStack p='5' pb='4' mb='2' w='full' h='full' align='center'>
      <TaskHeading
        headingTitle={task.title}
        description={task.description}
        subtitle={task.subtitle}
      />
      <VStack h='full' w='full'>
        <Flex w='full' h='full' minH='0'>
          <ImagePlacementImagesContainer images={remainingImages}/>
        </Flex>

        <TaskAdvice minH='unset' instructions={task.taskInstructions} />
      </VStack>
    </VStack>
  )
}
