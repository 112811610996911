import axios from 'axios'
import { AuthService } from './authService'
import { apiUrl } from './config'

// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token

const axiosAPIInstance = axios.create({
  baseURL: apiUrl,
})

// Request interceptor for API calls
axiosAPIInstance.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${AuthService.getToken()}`
    return config
  },

  err => Promise.reject(err),
)

// Response interceptor for API calls
axiosAPIInstance.interceptors.response.use(
  response => response,

  async error => {
    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      await AuthService.refreshToken()

      return axiosAPIInstance({
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${AuthService.getToken()}`,
        },
      })
    }

    return Promise.reject(error)
  },
)

export const getAxiosInstance = (url = apiUrl) => {
  const newAxiosInstance = axios.create({
    baseURL: url,
  })

  newAxiosInstance.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${AuthService.getToken()}`
      return config
    },

    err => Promise.reject(err),
  )

  // Response interceptor for API calls
  newAxiosInstance.interceptors.response.use(
    response => response,

    async error => {
      const originalRequest = error.config

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        await AuthService.refreshToken()

        return newAxiosInstance({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${AuthService.getToken()}`,
          },
        })
      }

      return Promise.reject(error)
    },
  )

  return newAxiosInstance
}

export default axiosAPIInstance
