export interface Organization {
  id: string
  name: string
  isActive: boolean
  municipalityId?: string
  municipalityName?: string
  city?: string
  numberOfPreschools?: number
  numberOfAdmins?: number
  numberOfUsers?: number
  type: OrganizationType
  ageType: AgeType
  address1?: string
  address2?: string
  zipCode?: string
  loginType?: LoginType
  showChildCode?: boolean
  hasMultipleSections?: boolean
}

export enum OrganizationType {
  KOMMUN = 'Municipality',
  FORSKOLA = 'Preschool',
}

export enum LoginType {
  SSO = 'SSO',
  CUSTOM = 'CUSTOM',
}

export enum AgeType {
  BIRTH_DATE = 'BirthDate',
  AGE = 'Age',
}

export const OrganizationTypeLabel: Record<OrganizationType, string> = {
  Municipality: 'Kommun',
  Preschool: 'Forskola',
}

export interface ViewOrganizationShape {
  name: string
  type: OrganizationType
  city: string
  address1: string
  address2: string
  zipCode: string
  municipalityId: string
}

export interface OrganizationDTO {
  id: string
  city: string
  type: OrganizationType
  name: string
  country: string
  zipCode: string
  address2: string
  isActive: boolean
  address1: string
  contactName: string
  contactEmail: string
  contactPhone: string
  municipalityId: string
}

export type CreateOrganizationDTO = Partial<Omit<OrganizationDTO, 'id'>>
export type UpdateOrganizationDTO = Partial<OrganizationDTO>

export interface OrganizationList {
  numberOfOrganizations: number
  numberOfPreschools: number
  numberOfUsers: number
  organizations: Organization[]
}
