import React from 'react'
import { useHistory } from 'react-router'

import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Text,
  Flex,
  Image,
  Button,
  HStack,
  Tooltip,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Error from 'assets/images/Error.svg'
import { TaskService } from 'services/taskService'
import {
  computeMaxStep,
  QUESTION_ACTIONS,
  useFollowUp,
} from 'providers/FollowUpProvider'

export default function NavButtons() {
  const { replace } = useHistory()
  const { currentTaskIndex, tasks, dispatch, currentTask } = useFollowUp()
  const { t } = useTranslation()
  const goBack = () => dispatch({ type: QUESTION_ACTIONS.NAV_PREV })
  const goNext = () => dispatch({ type: QUESTION_ACTIONS.NAV_NEXT })

  const finishFollowUp = () => {
    replace('/follow-up-comment')
  }

  const followUpIsComplete = TaskService.canSubmitTasks(tasks)

  const showPrevBtn = !(currentTaskIndex === 0 && currentTask?.step === 0)
  const showNextBtn = !(
    currentTaskIndex === tasks.length - 1 &&
    currentTask?.step === computeMaxStep(currentTask?.type)
  )
  const showFinishBtn = !showNextBtn

  return (
    <Flex w='full'>
      <HStack spacing='10' w='full' display='flex' justifyContent='flex-end'>
        {showPrevBtn && (
          <Box>
            <Button
              py='4'
              px='3'
              ml='auto'
              bg='transparent'
              variant='outline'
              fontSize='18px'
              onClick={goBack}
            >
              {t('Previous_Text')}
            </Button>
          </Box>
        )}

        {showNextBtn && (
          <Button
            rightIcon={<ArrowForwardIcon boxSize='6' />}
            onClick={goNext}
            ml='auto'
            py='4'
            px='3'
            fontSize='18px'
          >
            {t('Next_Button_Text')}
          </Button>
        )}

        {showFinishBtn && (
          <Box pos='relative' zIndex={0}>
            <Tooltip
              isOpen={!followUpIsComplete}
              hasArrow
              shouldWrapChildren
              placement='bottom-end'
              bg='red.600'
              mt='5'
              p='2'
              pos='relative'
              zIndex={0}
              label={
                <HStack pos='relative' zIndex={0} align='baseline'>
                  <Image src={Error} />
                  <Text
                    pos='relative'
                    zIndex={0}
                    fontWeight='light'
                    lineHeight='normal'
                  >
                    {t('Answer_All_Error_Tooltip')}
                  </Text>
                </HStack>
              }
            >
              <Button
                rightIcon={<ArrowForwardIcon boxSize='6' />}
                disabled={!followUpIsComplete}
                onClick={finishFollowUp}
                _hover={{ background: '#EF3A86' }}
                _selected={{ background: '#DC1F6E' }}
              >
                {t('End_Text')}
              </Button>
            </Tooltip>
          </Box>
        )}
      </HStack>
    </Flex>
  )
}
