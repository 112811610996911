import React from 'react'
import { ImagePlacementTask } from 'data-models/task-models'
import { useFollowUp } from 'providers/FollowUpProvider'
import { Flex, VStack, Center, useBreakpoint } from '@chakra-ui/react'
import ImagePlacementBase from './ImagePlacementBase'
import ImagePlacementItems from './ImagePlacementItems'
import { TaskHeading } from '../Common/TaskHeading'
import { TaskAdvice } from '../Common'
import useWindowDimensions from 'common/utils'

export default function ImagePlacementTaskCmp() {
  const { currentTask } = useFollowUp()
  const task = currentTask as ImagePlacementTask

  const { height } = useWindowDimensions()

  const breakpoint = useBreakpoint() || '2xl'
  const heightBreakpointReached = height <= 700

  const computeWidthByBreakpoint = () => {
    if (heightBreakpointReached) return '20%'

    switch (breakpoint) {
      case 'lg':
        return '20%'
      case 'md':
        return '20%'
      default:
        return 'unset'
    }
  }

  return (
    <Flex direction='column' h='full' w='full'>
      <VStack direction='column' align='stretch' p='5' h='full' w='full'>
        <Flex
          flexDir={
            ['xl', '2xl'].includes(breakpoint) && !heightBreakpointReached
              ? 'column'
              : 'row'
          }
          minH={'0'}
          h='100%'
          w='100%'
        >
          <TaskHeading
            description={task.description}
            subtitle={task.subtitle}
            headingTitle={task?.title}
            mr='2'
            maxW={computeWidthByBreakpoint()}
            h={
              ['xl', '2xl'].includes(breakpoint) && !heightBreakpointReached
                ? 'unset'
                : '100%'
            }
          />

          <Center
            w='full'
            h='full'
            minW='0'
            userSelect='none'
            flex='1'
            gridGap='8'
            minH='0'
            bottom={['xl', '2xl'].includes(breakpoint) ? 'unset' : '-5'}
            pos='relative'
            justifyContent={
              ['xl', '2xl'].includes(breakpoint) ? 'center' : 'flex-start'
            }
          >
            <ImagePlacementBase />
            <ImagePlacementItems />
          </Center>
        </Flex>

        <TaskAdvice
          instructions={task.taskInstructions}
          pb='0'
          mb='0'
          pt='60px'
        />
      </VStack>
    </Flex>
  )
}
