import React from 'react'
import { Flex, Image } from '@chakra-ui/react'
import { useDrag } from 'react-dnd'
import { apiBase } from 'services/config'
import { useFollowUp } from 'providers/FollowUpProvider'
import { ImageSortingTask } from 'data-models/task-models'


export default function ImagePlacementItem(props: { ipa: string}) {
  const { currentTask } = useFollowUp()
  const task = currentTask as ImageSortingTask
  let paddingValue;
  let imageFit;
  let imageBorderRadius;
 if(currentTask.name[1]==='Shapes'){
paddingValue = '15%'
 }
 else{
  paddingValue = '0'
 }

 if(currentTask.name[1]==='Shapes' && currentTask.knowledge[1] === 'Mathematics Level 2'){
  imageFit = 'contain';
  imageBorderRadius = '0'
   }
   else{
    imageFit = 'cover'
    imageBorderRadius = '2xl'
   }
  
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'image',
      item: { image: apiBase + props.ipa },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [props.ipa],
  )

  return (
    <Flex w='full' h='full' p='0' m='0' opacity={opacity} padding={paddingValue}>
      <Image
        w='full'
        h='full'
        p='0'
        m='0'
        fit={imageFit}
        src={apiBase + props.ipa}
        ref={dragRef}
        borderRadius={imageBorderRadius}
        zIndex='0'
        cursor='pointer'
      />
    </Flex>
  )
}
