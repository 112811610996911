import React from 'react'

import {
  Modal,
  Button,
  HStack,
  VStack,
  Textarea,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  useRadioGroup,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormWrapper } from 'common/Form'
import {
  FeedbackType,
  FeedbackTypeLabel,
  FeedbackTypeLabelEn,
  FeedbackTypeLabelDe
} from 'data-models'
import { useFeedbackModal } from '../hooks'
import { CustomRadioButton } from './CustomRadioButtonWithCheckmark'
import CheckIcon from 'common/CheckIcon'

interface FeedbackModalProps {
  isOpen: boolean
  onClose: () => void
}

export const FeedbackModal = ({ isOpen, onClose }: FeedbackModalProps) => {
  const { formMethods, isLoading, onSubmit } = useFeedbackModal({ onClose })
  const { register, control } = formMethods

  const options = [FeedbackType.REPORT_ISSUE, FeedbackType.SUGGESTION]
  const optionsen = [FeedbackType.REPORT_ISSUE, FeedbackType.SUGGESTION]

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'type',
    defaultValue: FeedbackType.REPORT_ISSUE,
  })

  const group = getRootProps()
  const { t, i18n } = useTranslation()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit} noValidate={true}>
          <ModalOverlay />
          <ModalContent h='fit-content' minH='200px'>
            <ModalHeader color='black'>{t('Feedback_Link')}</ModalHeader>
            <ModalCloseButton color='black' />
            <ModalBody>
              <VStack
                spacing={6}
                h='full'
                w='full'
                justifyContent={'flex-start'}
              >
                <FormWrapper name={'type'} label={''}>
                  <Controller
                    name='type'
                    control={control}
                    render={({ field }) => (
                      <HStack {...group} {...field}>
                       {i18n.language === 'en'
  ? optionsen.map(value => {
      const radio = getRadioProps({
        value,
      });
      return (
        <CustomRadioButton
          label={FeedbackTypeLabelEn[value]}
          key={value}
          {...radio}
        >
          <CheckIcon size={'16'} color='white' />
        </CustomRadioButton>
      );
    })
  : i18n.language === 'de'
  ? options.map(value => {
      const radio = getRadioProps({
        value,
      });
      return (
        <CustomRadioButton
          label={FeedbackTypeLabelDe[value]}  // Use FeedbackTypeLabelDe for German
          key={value}
          {...radio}
        >
          <CheckIcon size={'16'} color='white' />
        </CustomRadioButton>
      );
    })
  : options.map(value => {
      const radio = getRadioProps({
        value,
      });
      return (
        <CustomRadioButton
          label={FeedbackTypeLabel[value]}  // Default to other languages if not 'en' or 'de'
          key={value}
          {...radio}
        >
          <CheckIcon size={'16'} color='white' />
        </CustomRadioButton>
      );
    })}
                      </HStack>
                    )}
                  />
                </FormWrapper>

                <FormWrapper
                  name='feedback'
                  label={t('Feedback_Modal_TextBox_Title')}
                  isRequired
                >
                  <Textarea
                    w='full'
                    h='200px'
                    bgColor='white'
                    color='black'
                    placeholder={t('Feedback_Modal_TextBox_PlaceHolder')}
                    resize='none'
                    borderColor={'blackAlpha.300'}
                    mb='5'
                    minH='unset'
                    disabled={isLoading}
                    {...register('feedback')}
                  />
                </FormWrapper>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  fontSize='15px'
                  height='40px'
                  width='110px'
                  variant='outline'
                  bg='white'
                  color='#FF5099'
                  borderColor='#FF5099'
                  opacity={0.7}
                  _hover={{
                    bg: 'white',
                    color: '#FF5099',
                    opacity: 1,
                  }}
                  onClick={onClose}
                >
                  {t('Cancel_Button')}
                </Button>
                <Button
                  fontSize='15px'
                  height='40px'
                  width='110px'
                  type='submit'
                  isLoading={isLoading}
                >
                  {t('Send_Button_Text')}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}
