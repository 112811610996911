import React from 'react'
import {
  Image,
  HStack,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react'
import { Separator } from './Header.layout'
import WhiteLogo from 'assets/images/Myran.svg'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { useHistory, useLocation } from 'react-router-dom'
import { HelpSideBar, UserDetailsSideBar } from './components'
import LanguageSwitcher from 'common/LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { Task } from 'data-models'
import FullScreenMode from './components/FullScreenModel'

export const Header = () => {
  const { followupInfo,dispatch,tasks } = useFollowUp()
  const languages: string[] = ['se', 'en','de']
  const buttonBgColor = useColorModeValue('#2a0f74', '#2a0f74')
  const { pathname } = useLocation()
  const { t } = useTranslation()
  

  const pathsDisplayingHeader = ['/tasks', '/child-information']
  const history = useHistory()

  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const isChromeForiOS = /CriOS/.test(navigator.userAgent);
  const isChromeBrowser = isChrome || isChromeForiOS;
  const isEdge = /Edge/.test(navigator.userAgent);
  const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

  const currentPath = location.pathname
  let showHeader = true;
  currentPath === '/release-notes' ? showHeader = false : showHeader = true
  
  //const isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;


  const cancelFollowup = () => {
  // const taskArr = tasks.filter((task) => task.type === 'language_analysis') 
  // const taskArrIndexed: Task[] = taskArr.map((task, index) => {
  //   return { ...task, orderIndex: index + 1, isSkipped: false, step: 0 ,audioRecordings:[]}
  // })
  // dispatch({
  //   type: QUESTION_ACTIONS.SET_QUESTIONS,
  //   payload: taskArrIndexed,
  // })
    history.push('/welcome')
  }

  return (
    <HStack
      pt='3'
      pb='1'
      px='6'
      w='full'
      minH='50px'
      pos='relative'
      justify='space-between'
      className='header-wrapper'
    >
      <HStack spacing='4'>
        <Image src={WhiteLogo} />
        {pathsDisplayingHeader.includes(pathname) && (
          <>
            <Separator />
            <Text fontSize='md' fontWeight='500'>
              {followupInfo?.followupName}
            </Text>
          </>
        )}
      </HStack>
      { showHeader && 
      <HStack spacing='4' justify='flex-end' pr='4' h='full'>
        {pathsDisplayingHeader.includes(pathname) && (
          <>
            <Button onClick={cancelFollowup} variant='outline2' className='followup-cancel-btn'>
              {t('Cancel_Button')}
            </Button>
            <Separator />

            <HelpSideBar />
            <Separator />
          </>
        )}

        <LanguageSwitcher
          languages={languages}
          textColor='black'
          bgColor={buttonBgColor}
          globColor={true}
          activeColor='#301874'
          bgDrop='#301874'
          DropactiveColor='#301874'
          borderB={false}
        />
        <Separator />
        <UserDetailsSideBar />
         {(isChrome || isEdge) && ( 
        <>
          <Separator />
          <FullScreenMode />
        </>
     )} 
      </HStack>
}
    </HStack>
  )
}

