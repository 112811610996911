import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Text,
  useDisclosure,
  VStack,
  Image,
  IconButton,
  HStack,
  Flex,
} from '@chakra-ui/react'
import { useFollowUp } from 'providers/FollowUpProvider'
import Myran from 'assets/images/Myran-Logo.svg'
import Dropdown, { Option } from 'react-dropdown'
import { useUser } from 'providers/UserProvider'
import { usePreschool } from 'providers/PreschoolProvider'
import { AuthService } from 'services/authService'
import CollapseIcon from './CollapseIcon'
import { TaskService } from 'services/taskService'
import { BiMenu } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
export default function HamburgerMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { tasks, child, followupInfo } = useFollowUp()
  const { push } = useHistory()
  const [answeredCount, setAnsweredCount] = useState(0)
  const { t } = useTranslation()
  const { user } = useUser()
  const [options, setOptions] = useState<Option[]>([])
  const { preschool, preschools, setPreschool } = usePreschool()
  const [gender, setGender] = useState('')

  const logout = () => AuthService.logout()
  const navToFollowUp = () => push('/follow-up')
  const cancelFollowup = () => {
    push('/welcome')

    onClose()
  }

  function onSelect(selected: Option) {
    const ps = preschools.find(x => selected.value === x.id.toString())
    if (ps) setPreschool(ps)
  }

  useEffect(() => {
    if (child.gender) {
      if (child.gender === 'Male') {
        setGender('Pojke')
      } else if (child.gender === 'Female') {
        setGender('Flicka')
      }
    } else {
      if (
        window.location.pathname === '/tasks' ||
        window.location.pathname === '/follow-up-finish'
      )
        push('/welcome')
    }
  }, [child])

  useEffect(() => {
    setAnsweredCount(TaskService.getAnsweredCount(tasks))
  }, [tasks])

  useEffect(() => {
    setOptions(
      preschools.map(ps => ({
        value: ps.id.toString(),
        label: ps.name,
      })),
    )
  }, [preschools])

  return (
    <>
      <IconButton
        aria-label='menu-button'
        icon={<BiMenu fontSize='25px' />}
        variant='ghost'
        onClick={onOpen}
      ></IconButton>
      <Drawer placement='left' isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          borderRightRadius='15px'
          bgGradient='linear(to-b, purplishBlue, blue)'
          maxW='271px'
        >
          <DrawerHeader paddingTop='30px' paddingBottom='30px'>
            <Image src={Myran} />
            <DrawerCloseButton
              marginTop='16px'
              borderRadius='full'
              boxSize='10'
            >
              <CollapseIcon />
            </DrawerCloseButton>
          </DrawerHeader>

          <DrawerBody
            borderBottom='1px solid #3F35B7'
            borderTop='1px solid #3F35B7'
            px='30px'
            py='20px'
          >
            {child.birthdate ? (
              <Flex direction='column' fontSize='13px'>
                <Text fontWeight='light' mb='8'>
                  {t('Current_FollowUp')}
                </Text>
                <HStack spacing='30px' mb='7'>
                  <Text w='88px' fontWeight='light'>
                    {t('Follow_Up_Select_Box')}
                  </Text>
                  <Text fontSize='14px'>{followupInfo.followupName}</Text>
                </HStack>

                <HStack spacing='30px' mb='7'>
                  <Text w='88px' fontWeight='light'>
                    {t('DOB_Field')}
                  </Text>
                  <Text fontSize='14px'>{child.birthdate}</Text>
                </HStack>

                <HStack spacing='30px' mb='7'>
                  <Text w='88px' fontWeight='light'>
                    {t('Gender_Field')}
                  </Text>
                  <Text fontSize='14px'>{gender}</Text>
                </HStack>

                <VStack
                  w='full'
                  align='flex-start'
                  h='175px'
                  justify='space-evenly'
                >
                  <Divider
                    w='210px'
                    h='1px'
                    m='0'
                    borderColor='rgba(0, 0, 0, 0.2)'
                  />

                  {answeredCount && (
                    <Text fontSize='xs' color='#BDB3F7'>
                      {t('Completion_Done')} <b>{answeredCount}</b>{' '}
                      {t('Activities_So_Far')}
                    </Text>
                  )}

                  <Button
                    variant='outline'
                    fontSize='small'
                    height='40px'
                    onClick={cancelFollowup}
                  >
                    {t('Cancel_FollowUp')}
                  </Button>
                </VStack>
              </Flex>
            ) : (
              <Button
                variant='outline'
                fontSize='small'
                h='40px'
                onClick={navToFollowUp}
                hidden
              >
                {t('Start_New_FollowUp_Text')}
              </Button>
            )}
          </DrawerBody>
          <DrawerFooter p='30px'>
            <VStack
              w='full'
              align='flex-start'
              justify='space-between'
              spacing={'6'}
            >
              <Text w='full' fontSize='large' fontFamily='Catamaran'>
                {user.email}
              </Text>

              {options && options.length > 0 && tasks.length > 0 && (
                <Dropdown
                  options={options}
                  onChange={onSelect}
                  value={preschool.id ? preschool.name : ''}
                  placeholder={t('Choose_Preschool_Text')}
                  controlClassName='custom-control w-210'
                  arrowClassName='custom-arrow w-210'
                  menuClassName='custom-menu top'
                />
              )}
              <Button
                variant='outline'
                w='112px'
                h='40px'
                fontSize='small'
                onClick={logout}
              >
                {t('MainHeader_Logout')}
              </Button>
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
