import { Button, Menu, MenuButton, MenuDivider, MenuItemOption, MenuList, MenuOptionGroup, Image } from '@chakra-ui/react'
import React from 'react'
import LanguageSelect from '../../../../../assets/images/LanguageSelectorIconVideo.svg'
import { useTranslation } from 'react-i18next'

const VideoLanguage = ({onVideoLanguageChange}) => {
  sessionStorage.setItem('selectedVideoLanguage', "swedish");

  const handleLanguageChange = (value: string) => {
    // Save the selected language to session storage
    sessionStorage.setItem('selectedVideoLanguage', value);
    onVideoLanguageChange(value)
  }


  const { t } = useTranslation()
  return (
  
    <Menu closeOnSelect={true} placement='top-end' >
    <MenuButton as={Button}>
     <Image src={LanguageSelect}/>
    </MenuButton>
    <MenuList minWidth='100px' bgColor='#301874' borderColor='transparent' right={0}>
      <MenuOptionGroup defaultValue='swedish' type='radio' bgColor='#301874' onChange={(value) => handleLanguageChange(value.toString())}>
        <MenuItemOption value='swedish' bgColor='#301874'>{t('Swedish_Lang')}</MenuItemOption>
        <MenuItemOption value='english' bgColor='#301874'>{t('English_Lang')}</MenuItemOption>
        <MenuItemOption value='arabic' bgColor='#301874'>{t('Arabic_Lang')}</MenuItemOption>
        <MenuItemOption value='danish' bgColor='#301874'>{t('Danish_Lang')}</MenuItemOption>
        <MenuItemOption value='german' bgColor='#301874'>{t('German_Lang')}</MenuItemOption>
        <MenuItemOption value='finnish' bgColor='#301874'>{t('Finnish_Lang')}</MenuItemOption>
        <MenuItemOption value='french' bgColor='#301874'>{t('French_Lang')}</MenuItemOption>
        <MenuItemOption value='italian' bgColor='#301874'>{t('Italian_Lang')}</MenuItemOption>
        <MenuItemOption value='norwegian' bgColor='#301874'>{t('Norwegian_Lang')}</MenuItemOption>
      </MenuOptionGroup>
    </MenuList>
  </Menu>
  )
}

export default VideoLanguage
