import { Followup } from 'data-models/followup'
import axiosAPIInstance from './api'

const FollowupService = {
  getFollowups: (organizationId: string): Promise<Followup[]> => {
    return axiosAPIInstance
      .get(`/followups`, {
        params: {
          organizationId,
        },
      })
      .then(r => r.data)
  },
}

export { FollowupService }
