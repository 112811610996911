import React from 'react'

interface AvatarIconProps {
  color?: string
  size?: string | number
}

export const AvatarIcon = ({
  color = 'white',
  size = '24px',
}: AvatarIconProps) => (
  // <svg
  //   width={size}
  //   height={size}
  //   viewBox={`0 0 24 24`}
  //   color={color}
  //   fill='none'
  // >
  //   <path
  //     d='M5.121 17.804C7.21942 16.6179 9.58958 15.9963 12 16C14.5 16 16.847 16.655 18.879 17.804M15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z'
  //     stroke={color}
  //     strokeWidth='1'
  //     strokeLinecap='round'
  //     strokeLinejoin='round'
  //   />
  // </svg>
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.40039 19.1999C5.86156 18.6834 8.02144 16.3066 8.6544 16.3066H15.3468C16.264 16.3066 18.1364 18.2768 18.6004 18.9713M21.6004 11.9999C21.6004 17.3018 17.3023 21.5999 12.0004 21.5999C6.69846 21.5999 2.40039 17.3018 2.40039 11.9999C2.40039 6.69797 6.69846 2.3999 12.0004 2.3999C17.3023 2.3999 21.6004 6.69797 21.6004 11.9999ZM15.4392 8.72786C15.4392 6.89635 13.8931 5.3999 12.0007 5.3999C10.1083 5.3999 8.56213 6.89635 8.56213 8.72786C8.56213 10.5594 10.1083 12.0558 12.0007 12.0558C13.8931 12.0558 15.4392 10.5594 15.4392 8.72786Z'
      stroke='white'
      strokeWidth='2'
    />
  </svg>
)
