import React from 'react'
import { Flex, HStack } from '@chakra-ui/layout'
import { useRadioGroup, RadioProps, useRadio } from '@chakra-ui/radio'

const options = ['2', '3', '4', '5', '6']

interface AgeSelectorProps {
  age: string
  onChange: (age: string) => void
}

export const AgeSelector = ({ age, onChange }: AgeSelectorProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'age',
    value: age,
    defaultValue: '2',
    onChange,
  })

  return (
    <HStack
      {...getRootProps()}
      px='5'
      py='2'
      bg='darkIndigo'
      borderRadius='md'
      w='full'
      spacing='5'
      justify='space-between'
      className={age ? '' : 'addError'}
    >
      {options.map(value => {
        const radio = getRadioProps({ value })
        return (
          <AgeRadioButton key={value} {...radio}>
            {value}
          </AgeRadioButton>
        )
      })}
    </HStack>
  )
}

export const AgeRadioButton = (props: RadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Flex as='label' >
      <input {...input} />
      <Flex
        {...checkbox}
        bg='darkIndigo'
        cursor='pointer'
        borderRadius='md'
        border='none'
        _checked={{
          bg: 'pink.DEFAULT',
          color: 'white',
        }}
        px={5}
        py={3}
        m='0'
      >
        {props.children}
      </Flex>
    </Flex>
  )
}
