import React, { useEffect } from 'react'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { Flex, Center, Text, Button, Spinner } from '@chakra-ui/react'
import checkIcon from 'assets/images/check.svg'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import { TaskService } from 'services/taskService'
import { CloseIcon } from '@chakra-ui/icons'
import { useUser } from 'providers/UserProvider'
import { usePreschool } from 'providers/PreschoolProvider'
import { useTranslation } from 'react-i18next'
export default function FollowUpFinish() {
 
 
  const {
    dispatch,
    tasks,
    setChild,
    child,
    originalTasks,
    followupInfo,
    ownComment,
    groupComment,
    setOwnComment,
  } = useFollowUp()
  const { replace } = useHistory()
  const { user } = useUser()
  const { preschool, departmentId } = usePreschool()
  const { t } = useTranslation()
  const { loading, error, value } = useAsync(
    () =>{
      const filterTask = tasks.filter((task)=> task.type!=='language_analysis')
      if(tasks.length && child.gender){
          let payload :any = {
            followUpId: followupInfo.id,
            jsonValue: JSON.stringify(TaskService.answerSummary(filterTask)),
            teacherEmailAddress: user.email,
            childGender: child.gender,
         //   childBirthDate: child.birthdate ? new Date(child.birthdate).toISOString() : null,
            preschoolId: preschool.id,
            departmentId,
            organizationId: user.organizationId,
            individualComment: ownComment,
            groupComment: groupComment,
            childCode: child.code,
            audioRecordings: TaskService.audioSubmit(tasks)
          };

          if(child.age){
            payload = {...payload, childAge: child.age}
          }
          if(child.birthdate){
            payload = {...payload,childBirthDate:new Date(child.birthdate).toISOString()}
          }

          return TaskService.submitFollowUp(payload);
      }

      return  Promise.reject(new Error('No tasks provided'))},
    [],
  )

  useEffect(() => {
    if (error) console.log('Result submit error:', error)
  }, [error])

  useEffect(() => {
    dispatch({
      type: QUESTION_ACTIONS.SET_QUESTIONS,
      payload: originalTasks,
    })
    
    setOwnComment('')
  }, [])

  const startNewFollowUp = () => {
    setChild({ gender: '', birthdate: '', code: '' })
    dispatch({
      type: QUESTION_ACTIONS.UPDATE_CURRENT_TASK
    })
    
   
    replace('/welcome')
  }

  return (
    <Center h='full' w='full'>
      <Flex direction='column' align='center' fontFamily='Catamaran'>
        {loading && (
          <>
            <Spinner size='xl' />
            <Text mb='14' mt='7' fontSize='large' fontWeight='light'>
              {t('Complete_FollowUp')}
            </Text>
          </>
        )}

        {error && (
          <>
            <Center boxSize='60px' bgColor='red.600' borderRadius='full'>
              <CloseIcon fontSize='2xl' />
            </Center>
            <Text mb='14' mt='7' fontSize='large' fontWeight='light'>
              {t('Check_The_Logs_Error_Text')}
            </Text>
          </>
        )}

        {value && (
          <>
            <Center boxSize='60px' bgColor='#17B1AE' borderRadius='full'>
              <img src={checkIcon} alt='check' style={{ height: '50px' }} />
            </Center>
            <Text mb='14' mt='7' fontSize='large' fontWeight='light'>
              {t('FollowUp_Completed_Success')}
            </Text>
          </>
        )}

        <Button onClick={startNewFollowUp}>{t('Start_New_FollowUp_Text')}</Button>
      </Flex>
    </Center>
  )
}
