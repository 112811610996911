import React, { PropsWithChildren } from 'react'
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  HStack,
} from '@chakra-ui/react'
import { useBlockNavigation } from './useBlockNavigation'
import { useTranslation } from 'react-i18next'
interface BlockNavigationProps {
  exemptPaths: string[]
  onConfirmCallback?: () => void
}

export const BlockNavigation = ({
  children,
  exemptPaths,
  onConfirmCallback,
}: PropsWithChildren<BlockNavigationProps>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const leavePage = useBlockNavigation({ onBlockCallback: onOpen, exemptPaths })

  const handleConfirm = () => {
    if (onConfirmCallback) {
      onConfirmCallback()
    }
    leavePage()
  }
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent maxW='480px' p='2' color={'mainBlue.900'}>
          <ModalHeader>
            <Text color={'mainBlue.900'}>
              {t('Cancel_Current_FollowUp')}
            </Text>
          </ModalHeader>
          <ModalCloseButton
            color={'mainBlue.900'}
            colorScheme='blackAlpha'
            style={{ color: 'black' }}
          />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <HStack w='full' justifyContent={'flex-end'}>
              <Button
                variant={'outline'}
                color='blue'
                bgColor={'white'}
                fontWeight={'bold'}
                borderColor='blue'
                border={'2px solid'}
                _hover={{ opacity: 0.7 }}
                onClick={onClose}
                mr='2'
              >
                {t('No_Continue_Button_Text')}
              </Button>
              <Button onClick={handleConfirm}>{t('Yes_Cancel_Text')}</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
