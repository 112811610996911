import React, { PropsWithChildren, ReactNode } from 'react'
import {
  Flex,
  Text,
  HStack,
  Heading,
  StackProps,
  VStack,
} from '@chakra-ui/react'

import TaskTag from '../../TaskTag'
import { MultiTaskTitle, Task } from 'data-models/task-models'
import { useFollowUp } from 'providers/FollowUpProvider'
import { useTranslation } from 'react-i18next'

export function TaskHeadingDescription(props: PropsWithChildren<{}>) {
  return (
    <Flex>
      <TaskTag>{props.children}</TaskTag>
    </Flex>
  )
}
export function TaskHeadingTitle(props: PropsWithChildren<{}>) {
  return (
    <Heading
      w='full'
      textAlign='start'
      as='h1'
      fontSize='2xl'
      fontWeight='semibold'
      mt='3'
      lineHeight='1'
    >
      {props.children}
    </Heading>
  )
}
export function TaskHeadingSubtitle(props: PropsWithChildren<{}>) {
  return (
    <Text fontWeight='semibold' mt='3'>
      {props.children}
    </Text>
  )
}

interface TaskHeadingProps extends StackProps {
  description: string
  headingTitle?: ReactNode
  subtitle?: ReactNode
}
export const TaskHeading = ({ ...rest }: TaskHeadingProps) => {
  const { currentTaskIndex, currentTask } = useFollowUp()
  const { step, title, id, criteria } = currentTask
  const { i18n } = useTranslation()

  return (
    <HStack w='full' align='center' {...rest}>
      <Flex justify='center' align='center' h='full'>
        <Text
          px='3'
          py='3'
          w='fit-content'
          fontSize='20px'
          color='white'
          bg='#2A0F74'
          borderRadius='100'
        >
          0{currentTaskIndex + 1}
        </Text>
      </Flex>
      <VStack>
        <Text textAlign='start' w='full'>
        {i18n.language === 'en' ? criteria[1] : i18n.language === 'de' ? criteria[2] : criteria[0]}
        </Text>
        <TaskHeadingTitle key={`${id}_${title}_${step}`}>
          {i18n.language === 'en' ? title[1] : i18n.language === 'de' ? title[2] : title[0]}
        </TaskHeadingTitle>
      </VStack>
    </HStack>
  )
}

const getMultitaskTitle = (task: Task) => {
  const { step, title, name } = task

  switch (step) {
    case 1:
      return title.a
    case 2:
      return title.b
    case 0:
    default:
      return name
  }
}

interface MultiTaskHeadingProps extends StackProps {
  description: ReactNode
  headingTitle: MultiTaskTitle
}

export const MultiTaskHeading = ({ ...rest }: MultiTaskHeadingProps) => {
  const { currentTaskIndex, currentTask } = useFollowUp()
  const { step, id, criteria } = currentTask
  const { i18n } = useTranslation()
  const title = getMultitaskTitle(currentTask)

  return (
    <HStack w='full' align='center' {...rest}>
      <Flex justify='center' align='flex-end' h='full'>
        <Text
          px='3'
          py='3'
          w='fit-content'
          fontSize='20px'
          color='white'
          bg='#2A0F74'
          borderRadius='100'
        >
          0{currentTaskIndex + 1}
        </Text>
      </Flex>
      <VStack>
        <Text textAlign='start' w='full'>
        {i18n.language === 'en' ? criteria[1] : i18n.language === 'de' ? criteria[2] : criteria[0]}
        </Text>
        <TaskHeadingTitle key={`${id}_${title}_${step}`}>
        {i18n.language === 'en' ? title[1] : i18n.language === 'de' ? title[2] : title[0]}
        </TaskHeadingTitle>
      </VStack>
    </HStack>
  )
}
