import React, { CSSProperties, useEffect, useState } from 'react'
import { HStack, AspectRatio, Center, Box } from '@chakra-ui/react'
import BoyIcon from './BoyIcon'
import GirlIcon from './GirlIcon'
import { Gender } from '../data-models/enums'
import { CheckIcon } from '@chakra-ui/icons'

const selectedStyle: CSSProperties = {
  border: '3px solid #ff5099',
  backgroundColor: '#ffffff',
}
const unselectedStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  border: 'none'
}

export default function GenderPicker(props: {
  onValueChange?: Function
  genderSelect?: Gender
}) {
  const [selectedGender, setSelectedGender] = useState<Gender>()
  const isBoy = selectedGender === Gender.boy
  const isGirl = selectedGender === Gender.girl

  const selectBoy = () => setSelectedGender(Gender.boy)
  const selectGirl = () => setSelectedGender(Gender.girl)

  useEffect(() => {
    if (props.onValueChange) props.onValueChange(selectedGender)
  }, [selectedGender])

  return (
    <HStack
      w='12'
      h='100'
      spacing='4'
      minH='100px'
      minW='200px'
      align='left'
      borderRadius='xl'
      justifyContent='left'
      className='gender-picker-wrapper'
    >
      <Box position='relative' w='full'>
      <Center
      //  ratio={1}
        onClick={selectBoy}
        cursor='pointer'
        borderRadius='xl'
        p='10%'
        h='full'
        w='full'
        overflow='hidden'
        style={isBoy ? selectedStyle : unselectedStyle}
        className={props.genderSelect ? '' : 'addError'}
      >
        <BoyIcon/>
      </Center>
      {isBoy
      ?
      ( <Center
        pos='absolute'
        left='2'
        top='2'
        boxSize='5'
        bgColor='pink.500'
        borderRadius='full'
      >
        <CheckIcon color='white' />
      </Center>)
      :
      <Center
      pos='absolute'
      left='2'
      boxSize='5'
      bgColor='lightgray'
      borderRadius='full'
      top='2'
    >
      <CheckIcon color='white' />
    </Center>
    }
      {/* <Center
        pos='absolute'
        left='1'
        boxSize='5'
        bgColor='pink.500'
        borderRadius='full'
        top='1'
      >
        <CheckIcon color='lightgray' />
      </Center> */}
</Box>
<Box position='relative' w='full'>
      <Center
     //   ratio={1}
        onClick={selectGirl}
        cursor='pointer'
        borderRadius='xl'
        p='10%'
        h='full'
        w='full'
        overflow='hidden'
        style={isGirl ? selectedStyle : unselectedStyle}
        className={props.genderSelect ? '' : 'addError'}
      >
        <GirlIcon  />
      </Center>
      {isGirl
      ?
      ( <Center
        pos='absolute'
        left='2'
        boxSize='5'
        bgColor='pink.500'
        borderRadius='full'
        top='2'
      >
        <CheckIcon color='white' />
      </Center>)
      :
      <Center
      pos='absolute'
      left='2'
      boxSize='5'
      bgColor='lightgray'
      borderRadius='full'
      top='2'
    >
      <CheckIcon color='white' />
    </Center>
    }
  </Box>
    </HStack>
  )
}
