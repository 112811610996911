import React from 'react'
import { useTeacherComment } from './useTeacherComment'
import ExpandablePageContainer from 'common/ExpandablePageContainer'
import { CommentSection } from './components'
import { Button, Flex, HStack, VStack } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
export const TeacherComment = () => {
  const { ownComment, setOwnComment, onClose, onConfirm } = useTeacherComment()
  const { t } = useTranslation()
  return (
    <ExpandablePageContainer showFullscreenToggle={true}>
      <Flex h='full' w='full' alignItems={'center'}>
        <VStack h='full' w='full' justifyContent='center'>
          <VStack w='40%'>
            <CommentSection
              label={t('Own_Notes_Title')}
              value={ownComment}
              onChange={setOwnComment}
              helpLabel={t('Hint_Title')}
              helpText={t('Hint_Explanation')}
            />

            {/* <CustomDivider />

            <CommentSection
              label={'Anteckningar till gruppen'}
              value={groupComment}
              onChange={setGroupComment}
              helpLabel='Antydan'
              helpText='Denna kommentar kommer att vara synlig för hela gruppen.'
            /> */}
          </VStack>

          <HStack pt='8' w='full' justifyContent={'center'}>
            <Button
              variant={'outline'}
              fontWeight={'semibold'}
              _hover={{ opacity: 0.7 }}
              onClick={onClose}
              mr='2'
              fontSize={'16px'}
            >
              {t('Previous_Text')}
            </Button>
            <Button onClick={onConfirm} fontSize={'16px'}>
              {t('Submit_Button_Text')} <ArrowForwardIcon ml='2' />{' '}
            </Button>
          </HStack>
        </VStack>
      </Flex>
    </ExpandablePageContainer>
  )
}
