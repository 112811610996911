import React from 'react'

export default function CollapseIcon(props: {
  color?: React.SVGAttributes<SVGSVGElement>['stroke']
  width?: React.SVGAttributes<SVGSVGElement>['width']
  height?: React.SVGAttributes<SVGSVGElement>['height']
}) {
  const { width = '24px', color = '#fff' } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height='24px'
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M5.535 12.027h13.56M10.4 17.547h8.694M10.4 6.995h8.694' />
      <path d='m7.926 8.966-3 3.084 3 3' />
    </svg>
  )
}
