import React, { useState, memo } from 'react';
import './waves-animation.css';

const Waves = () => {
  const wavesArray = new Array(60).fill(null);
  const [bgColor, setBgColor] = useState('gray'); 

  return (
    <div className="wavecontainer">
      {wavesArray.map((_, index) => {
        const randomNumber = Math.floor(Math.random() * 30) + 1;
        return (
          <div
            key={index}
            className={`waves wave-${index}`}
            style={{ height: `${randomNumber}px`, backgroundColor: bgColor }}
          ></div>
        );
      })}

    </div>
  );
};

export default memo(Waves);
