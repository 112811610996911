import React from 'react'

interface FeedbackIconProps {
  color?: string
  size?: string | number
}

export const FeedbackIcon = ({
  color = '#000000',
  size = '32px',
}: FeedbackIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 32 32'
    preserveAspectRatio={''}
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    fill='transparent'
    stroke={color}
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='0.5'
  >
    <path
      d='M12 16H12.01M16 16H16.01M20 16H20.01M25 16C25 20.418 20.97 24 16 24C14.5286 24.005 13.0748 23.6808 11.745 23.051L7 24L8.395 20.28C7.512 19.042 7 17.574 7 16C7 11.582 11.03 8 16 8C20.97 8 25 11.582 25 16Z'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
