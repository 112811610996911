export const myranUrl = `${process.env.REACT_APP_MYRAN_URL}`
const search = '/api'
const n = 2
export const apiBase = process.env.REACT_APP_API_BASE?.replace(
  RegExp('^(?:.*?api){' + n + '}'),
  function (x) {
    return x.replace(RegExp(search + '$'), '')
  },
)
// export const apiBase = process.env.REACT_APP_API_BASE?.replace('/api', '')

const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY

export const apiUrl = `${process.env.REACT_APP_API_BASE}`
export const tokenUrl = `${process.env.REACT_APP_FIREBASE_TOKEN_URL}?key=${firebaseApiKey}`
export const clientId = `${process.env.REACT_APP_CLIENT_ID}`
export const adminUrl = `${process.env.REACT_APP_ADMIN_URL}`
export const idpUrl = `${process.env.REACT_APP_IDP_URL}`
