import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { theme } from './theme'
import { ChakraProvider } from '@chakra-ui/react'
import { UserProvider } from './providers/UserProvider'
import { BrowserRouter } from 'react-router-dom'
import GA4React from 'ga-4-react'
import i18n from 'i18next'
import enTranslation from './locales/en.json'
import svTranslation from './locales/sv.json'
import deTranslation from './locales/de.json'
import { I18nextProvider } from 'react-i18next'

const browserLocaleLanguage = navigator.language.split(/[-_]/)[0]
let siteLanguage
if (browserLocaleLanguage === 'sv') {
  siteLanguage = 'se'
} else if(browserLocaleLanguage === 'en'){
  siteLanguage = 'en'
}
else{
  siteLanguage = 'de'
}


i18n.init({
  interpolation: { escapeValue: false },
  lng: sessionStorage.getItem('language-selected') ? sessionStorage.getItem('language-selected'): siteLanguage, // Default language
  resources: {
    en: {
      translation: enTranslation,
    },
    se: {
      translation: svTranslation,
    },
    de: {
      translation: deTranslation
    }
  },
})

const googleAnalytics4 = new GA4React(
  process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID!,
)

;(async () => {
  try {
    await googleAnalytics4.initialize()
  } catch (error) {
    console.log('Google analytics initialization error:', error)
  }

  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <ChakraProvider theme={theme}>
          <UserProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </UserProvider>
        </ChakraProvider>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()
