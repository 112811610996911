import React from 'react'
import { VStack, HStack, Text, StackProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
interface TaskAdviceProps extends StackProps {
  instructions: string
}

export const TaskAdvice = ({ instructions, ...rest }: TaskAdviceProps) => {
  const { t, i18n } = useTranslation()
  return (
    <VStack
      w='full'
      minH='80px'
      pb='35px'
      justifyContent='center'
      align='stretch'
      {...rest}
    >
      <HStack>
        <Text lineHeight='18px' fontSize='sm'>
          <span style={{ fontWeight: 'bold' }}>{t('Note_Text')}: </span>
          {i18n.language === 'en' ? instructions[1] : i18n.language === 'de' ? instructions[2] : instructions[0]}
        </Text>
      </HStack>
    </VStack>
  )
}
