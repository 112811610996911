import React, { useMemo,useState,useEffect } from 'react'
import { CheckboxAnswer, VideoMultiTask } from 'data-models/task-models'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { useFullscreen } from 'providers/FullscreenProvider'
import {
  Tab,
  Flex,
  Tabs,
  VStack,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react'
import { TaskAdvice } from '../Common'
import VideoPlayer from './VideoPlayer'
import { apiBase } from 'services/config'
import { MultiTaskHeading } from '../Common/TaskHeading'
import CheckboxAnswerGroup from 'common/CheckboxAnswerGroup/CheckboxAnswerGroup'
import { useTranslation } from 'react-i18next'
export default function VideoMultiTaskCmp() {
  const { isFullscreen } = useFullscreen()

  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as VideoMultiTask
  const currentTaskPart = useMemo(
    () => (currentTask.step <= 1 ? 'a' : 'b'),
    [currentTask.step],
  )

  const onAnswerChange = (answers: CheckboxAnswer[]) => {
    currentTaskPart === 'a'
      ? dispatch({
          type: QUESTION_ACTIONS.UPDATE_ANSWERS,
          payload: { a: answers, b: task.answerOptions.b },
        })
      : dispatch({
          type: QUESTION_ACTIONS.UPDATE_ANSWERS,
          payload: { a: task.answerOptions.a, b: answers },
        })
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }

  const onChangeTab = (index: number) => {
    dispatch({ type: QUESTION_ACTIONS.SET_CURRENT_STEP, payload: index })
  }


  // check what language video is selected in the dropdown
  const [selectedVideoLanguage,setSelectedVideoLanguage] = useState('swedish');

  useEffect(() => {
    
  }, [selectedVideoLanguage]);

  const handleVideoLanguageChange = (lang) => {
    setSelectedVideoLanguage(lang);
  };

  const getVideoSrc = () => {
    // for video tasks, when language other than swedish is selected, we remove -SE from the url and replace it with -EN.
   // const urlParts = task.videoUrl.split('-');
   let languageSuffix = '';
   switch (selectedVideoLanguage) {
     case 'arabic':
       languageSuffix = 'AR'
       break;
       case 'danish':
         languageSuffix = 'DA'
         break;
         case 'german':
       languageSuffix = 'DE'
       break;
       case 'english':
       languageSuffix = 'EN'
       break;
       case 'finnish':
        languageSuffix= 'FI'
        break;
        case 'french':
        languageSuffix= 'FR'
        break;
        case 'norwegian':
        languageSuffix= 'NO'
        break;
        case 'italian':
        languageSuffix= 'IT'
        break;
     default:
       languageSuffix ='SE'
       break;
   }
  
   const hyphenIndex = task.videoUrl.lastIndexOf('-');
   const baseUrl = task.videoUrl.substring(0, hyphenIndex);
   return `${apiBase}${baseUrl}-${languageSuffix}.mp4`;
 // }
};
  const { t } = useTranslation()
  return (
    <Flex direction='column' h='full' w='full'>
      <VStack p='5' pb='2' mb='2' w='full' h='full' align='center'>
        <MultiTaskHeading
          description={task.description}
          headingTitle={task.title}
        />

        <Tabs
          w='full'
          h='full'
          pos='relative'
          variant='unstyled'
          onChange={onChangeTab}
          index={currentTask.step}
        >
          <TabList pb='4' pt='3'>
            <Flex
              h='0'
              w='full'
              pos='absolute'
              alignSelf='center'
              justifySelf='center'
              borderTop='1px solid #2A0F74'
              zIndex={0}
            />
            <Tab
              px={4}
              py={2}
              mr={6}
              zIndex={1}
              border='1px solid #2A0F74'
              fontSize='xs'
              borderRadius='8px'
              bgColor='#6c40f6'
              _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
              _focus={{ boxShadow: 'none' }}
            >
              <span style={{ opacity: 0.5, paddingRight: '10px' }}>{t('Step')} 1</span>
              {t('Explain_Scenario_Title')}
            </Tab>
            <Tab
              px={4}
              py={2}
              mr={6}
              zIndex={10}
              border='1px solid #2A0F74'
              bgColor='#6C40F6'
              fontSize='xs'
              borderRadius='8px'
              _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
              _focus={{ boxShadow: 'none' }}
            >
              <span style={{ opacity: 0.5, paddingRight: '10px' }}>
                {t('Step')} 2 {t('Part')} 1
              </span>
              {t('Mark_All_Options_Text')}
            </Tab>
            <Tab
              px={4}
              py={2}
              zIndex={10}
              border='1px solid #2A0F74'
              bgColor='#6C40F6'
              fontSize='xs'
              borderRadius='8px'
              _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
              _focus={{ boxShadow: 'none' }}
            >
              <span style={{ opacity: 0.5, paddingRight: '10px' }}>
                {t('Step')} 2 {t('Part')} 2
              </span>
              {t('Mark_All_Options_Text')}
            </Tab>
          </TabList>

          <TabPanels h='full' w='full' py={0}>
            <TabPanel h='full' w='full'>
              <Flex direction='column' w='full' h='full'>
                <Flex
                  h='full'
                  w='full'
                  flex='1'
                  align='center'
                  justify='center'
                  direction='column'
                >
                  <VideoPlayer
                    isFullscreen={isFullscreen}
                    videoSrc={getVideoSrc()}
                    onVideoLanguageChange = {handleVideoLanguageChange}
                  />
                </Flex>
                <TaskAdvice
                  instructions={task.taskInstructions}
                  marginTop='auto !important'
                />
              </Flex>
            </TabPanel>

            <TabPanel w='full' h='full' py={0}>
              <Flex direction='column' h='full' w='full'>
                <VStack
                  w='full'
                  h='full'
                  align='center'
                  flex='1'
                  minH='0'
                  minW='0'
                >
                  <CheckboxAnswerGroup
                    options={task.answerOptions.a}
                    onChange={onAnswerChange}
                  />
                  <TaskAdvice
                    instructions={task.answerInstructions}
                    marginTop='auto !important'
                  />
                </VStack>
              </Flex>
            </TabPanel>

            <TabPanel w='full' h='full' maxH='full'>
              <Flex direction='column' h='full' w='full'>
                <VStack w='full' h='full' minH='0' minW='0' align='center'>
                  <CheckboxAnswerGroup
                    options={task.answerOptions.b}
                    onChange={onAnswerChange}
                  />
                  <TaskAdvice
                    instructions={task.answerInstructions}
                    marginTop='auto !important'
                  />
                </VStack>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Flex>
  )
}
