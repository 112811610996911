import React from 'react'
import {
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react'
import { useAudio } from 'react-use'
import { secondsToMMSS } from 'common/utils'

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
export default function AudioPlayer(props: { audioSrc: string }) {
  const [audio, { duration, time, playing }, { pause, play, seek }] = useAudio({
    src: props.audioSrc,
  })

  return (
    <Flex direction='column' width='85%' mt='10' align='center'>
      {audio}
      <Text alignSelf='flex-end' fontSize='small' mb='2' fontFamily='Catamaran'>
        {secondsToMMSS(time)} / {secondsToMMSS(duration)}
      </Text>
      <Slider
        aria-label='track-progress'
        value={time}
        min={0}
        max={duration}
        focusThumbOnChange={false}
        onChange={seek}
        mb='10'
      >
        <SliderTrack bg='darkIndigo' h='1.5' borderRadius='md'>
          <SliderFilledTrack bg='pink.500' />
        </SliderTrack>
        <SliderThumb boxSize='7' boxShadow='lg'>
          <Box borderRadius='full' bgColor='pink.500' boxSize='3'></Box>
        </SliderThumb>
      </Slider>

      {playing ? (
        <IconButton
          aria-label='pause-btn'
          // icon={<PauseIcon />}
          onClick={pause}
          boxSize='70px'
          borderRadius='full'
        />
      ) : (
        <IconButton
          aria-label='play-btn'
          // icon={<PlayIcon />}
          onClick={play}
          boxSize='70px'
          borderRadius='full'
        />
      )}
    </Flex>
  )
}
