import React from 'react'
import {
  Box,
  FormLabel,
  FormControl,
  FormControlProps,
  FormErrorMessage,
} from '@chakra-ui/react'
import { isNil } from 'lodash-es'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FormWrapperProps extends FormControlProps {
  name: string
  label: string
}

export const FormWrapper = ({
  name,
  label,
  children,
  ...rest
}: FormWrapperProps) => {
  const formContext = useFormContext()
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = formContext
  const isInvalid = !isNil(errors[name])
  return (
    <FormControl isInvalid={isInvalid} {...rest}>
      <FormLabel fontSize='12px' color='#5A738B'>
        {label}
      </FormLabel>
      <Box>{children}</Box>
{errors[name] && (
        <FormErrorMessage>
          {t(errors[name]?.message) || errors[name]?.message} {/* Use translation if available, otherwise fallback to default message */}
        </FormErrorMessage>
)}
    </FormControl>
  )
}
