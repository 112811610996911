import React from 'react'
import {
  Button,
  Flex,
  HStack,
  VStack,
  Text,
  Box,
  Heading,
  Input,
} from '@chakra-ui/react'
import { useLoginAvesta } from './hooks'
import { adminUrl } from 'services/config'
import { useTranslation } from 'react-i18next'

const invalidCredentialsErrorMessage = 'Inloggningen misslyckades'

export const LoginAvesta = () => {
  const { handleLogin, authError, isLoading } = useLoginAvesta()
  const { t } = useTranslation()
  return (
    <Flex w='100vw' h='100vh' justifyContent='center' alignItems='center'>
      <Box
        borderRadius='5%'
        bgColor='darkIndigo'
        borderColor='rgba(255, 255, 255, .3)'
        borderWidth='1px'
        pos='absolute'
        zIndex='0'
        h='420px'
        w='429px'
        top='50%' // Move to 50% from top
        left='50%' // Move to 50% from left
        transform='translate(-50%, -50%)' // Centering trick
      >
        <VStack w='full' alignItems='stretch'>
          <Heading
            as='h1'
            fontSize='22'
            fontWeight='semibold'
            lineHeight='1.6'
            mt={10}
            ml={10}
          >
            {t('Single_sign-on')}
          </Heading>
          <Text
            mt='7'
            width='380px'
            fontSize='12'
            pl={10}
            lineHeight='6'
            fontWeight='light'
          >
            {t('Fisrt_paragraph_SSO')}
          </Text>
          <Text
            mt='7'
            width='380px'
            fontSize='12'
            lineHeight='6'
            pl={10}
            fontWeight='light'
          >
            {t('Second_paragraph_SSO')}
          </Text>

          <VStack ml='5' w='full' justifyContent='center' paddingTop='10px'>
            <Input
              h='46px'
              w='342px'
              backgroundColor={'white'}
              color='black'
              placeholder={t('Your_organisation_email')}
              _placeholder={{ color: 'black' }}
              className='remove-input-auto-fill'
              mb={5}
            />
            <Button
              height={10}
              w='342px'
              fontSize={14}
              variant={'solid'}
              type='submit'
              onClick={handleLogin}
              // rightIcon={<ArrowForwardIcon boxSize='6' />}
              isLoading={isLoading}
            >
              {t('Login_With_SSO')}
            </Button>
            <Box pt={16}>
              <Flex>
                <Text fontSize='12px' fontWeight='light' color='white'>
                  <a
                    href='https://myranappen.se/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('Website_Link')}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href={adminUrl} target='_blank' rel='noreferrer'>
                    Admin
                  </a>
                </Text>
              </Flex>
            </Box>
          </VStack>
          {authError && (
            <HStack w='full' justifyContent='center' pt='4'>
              <Text fontWeight='light' color='white'>
                {invalidCredentialsErrorMessage}
              </Text>
            </HStack>
          )}
        </VStack>
      </Box>
    </Flex>
  )
}
