import { useState, useRef } from 'react';

const useRecording = () => {
  const [isRecording1, setIsRecording1] = useState(false);
  const intervalIdRef = useRef<NodeJS.Timeout>();

  const handleRecord1 = () => {
    let index = 0;
    console.log('Recording started');
    setIsRecording1(true);
    intervalIdRef.current = setInterval(() => {
      console.log('Recording...');
      if (index < 70) {
        const waveDiv: HTMLElement | null = document.querySelector(`.wave-${index}`);
        if (waveDiv) {
          waveDiv.style.backgroundColor = 'white';
        }
        index++;
      } else {
        clearInterval(intervalIdRef.current as NodeJS.Timeout);
        const waveDivs: NodeListOf<HTMLElement> = document.querySelectorAll('.waves');
        waveDivs.forEach(waveDiv => {
          waveDiv.style.backgroundColor = 'gray';
        });
        index = 0;
        handleRecord1();
      }
    }, 500);
  };

  const handleStop1 = () => {
    console.log('Recording stopped');
    clearInterval(intervalIdRef.current as NodeJS.Timeout);
    setIsRecording1(false);
    const waveDivs: NodeListOf<HTMLElement> = document.querySelectorAll('.waves');
    waveDivs.forEach(waveDiv => {
      waveDiv.style.backgroundColor = 'gray';
    });
  };

  return { isRecording1, handleRecord1, handleStop1 };
};

export default useRecording;
