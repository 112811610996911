import React, { useEffect, useState } from 'react'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import {
  Tab,
  Box,
  Flex,
  Tabs,
  Image,
  VStack,
  Center,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react'
import { CheckboxAnswer, CheckboxTask } from 'data-models/task-models'
import { CheckIcon } from '@chakra-ui/icons'
import CheckboxAnswerGroup from 'common/CheckboxAnswerGroup/CheckboxAnswerGroup'
import { apiBase } from 'services/config'
import { TaskHeading } from './Common/TaskHeading'
import { TaskAdvice } from './Common'
import { useTranslation } from 'react-i18next'
// import soi from './banana.mp3'
export default function CheckboxTaskCmp() {
  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as CheckboxTask
  const { t, i18n } = useTranslation()
  //answer options animate when page is rerendered, so to prevent it the clases are removed when rendering.
  useEffect(()=>{
    clearAnimation();
  },[])

  const clearAnimation = () => {
    if (task && task.images) {
      task.images.forEach((item)=>{
        const imageOptionsUnSelected = document.getElementsByClassName('image-unselected');
        Array.from(imageOptionsUnSelected).forEach(imageOption => {
          // Remove the 'image-unselected' class from each element
          imageOption.classList.remove('image-unselected');
        });
      if(item.checked){
        const imageOptionsSelected = document.getElementsByClassName('image-clicked');
        Array.from(imageOptionsSelected).forEach(imageOption => {
          // Remove the 'image-clicked' class from each element
          imageOption.classList.remove('image-clicked');
        });
       
      }
    })
  }
  }
  function onSelectedImageChange(changed: string) {
    const images = task.images?.map(img => ({
      ...img,
      checked: img.url === changed ? !img.checked : img.checked,
    }))

    
    dispatch({
      type: QUESTION_ACTIONS.UDPATE_QUESTION,
      payload: { images },
    })
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }

  const onAnswerChange = (answers: CheckboxAnswer[]) => {
    dispatch({ type: QUESTION_ACTIONS.UPDATE_ANSWERS, payload: answers })
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }

  const onChangeTab = (index: number) => {
    clearAnimation();
    dispatch({ type: QUESTION_ACTIONS.SET_CURRENT_STEP, payload: index })
  }
  const [isSoundPlaying, setIsSoundPlaying] = useState({})
  const playSound = soundUrl => {
    const audio = new Audio(soundUrl)
    audio.play()

    setIsSoundPlaying(prevState => ({
      ...prevState,
      [soundUrl]: true,
    }))
    audio.onended = () =>
      setIsSoundPlaying(prevState => {
        const newState = { ...prevState }
        delete newState[soundUrl] // Remove the soundUrl from state
        return newState
      })
  }



  return (
    <Flex direction='column' h='full' w='full'>
      <VStack p='5' pb='4' mb='2' w='full' h='full' align='center'>
        <TaskHeading
          description={task.description}
          subtitle={task.subtitle}
          headingTitle={task.title}
        />
        <Tabs
          h='full'
          w='full'
          pos='relative'
          variant='unstyled'
          onChange={onChangeTab}
          index={currentTask.step}
        >
          <TabList pb='4' pt='3'>
            <Flex
              h='0'
              w='full'
              pos='absolute'
              alignSelf='center'
              justifySelf='center'
              borderTop='1px solid #2A0F74'
              zIndex={0}
            />
            <Tab
              px={4}
              py={2}
              mr={6}
              zIndex={1}
              border='1px solid #2A0F74'
              fontSize='xs'
              borderRadius='8px'
              bgColor='#6c40f6'
              _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
              _focus={{ boxShadow: 'none' }}
            >
              <span style={{ opacity: 0.5, paddingRight: '10px' }}>
                {t('Step')} 1
              </span>
              {t('Explain_Scenario_Title')}
            </Tab>
            <Tab
              px={4}
              py={2}
              zIndex={10}
              border='1px solid #2A0F74'
              bgColor='#6C40F6'
              fontSize='xs'
              borderRadius='8px'
              _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
              _focus={{ boxShadow: 'none' }}
            >
              <span style={{ opacity: 0.5, paddingRight: '10px' }}>
                {t('Step')} 2
              </span>
              {t('Mark_All_Options_Text')}
            </Tab>
          </TabList>

          <TabPanels h='full' w='full' py={0}>
            <TabPanel h='full' w='full'>
              <VStack w='full' h='full'>
                {task.imageUrl && (
                  <Flex
                    h='full'
                    w='full'
                    justify='center'
                    overflow='hidden'
                    pos='relative'
                  >
                    <Flex
                      pos='absolute'
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                      w='full'
                      h='full'
                      justify='center'
                    >
                      <Image
                        borderRadius={'8px'}
                        bg='white'
                        px='2'
                        py='2'
                        h='full'
                        style={{
                          objectFit: 'contain',
                        }}
                        src={apiBase + task.imageUrl}
                        alt='task_image'
                        overflow='auto'
                      />
                    </Flex>
                  </Flex>
                )}

                {task.images && (
                  <Center w='full' h='full' className='hello'>
                    <Flex
                      w='full'
                      h='full'
                      alignItems='center'
                      wrap='wrap'
                      justify={task.imageView ? 'space-evenly' : 'center'}
                    >
                      {task.images?.map(img => (
                        <Center key={img.url} >
                          <Flex flexDirection='column' alignItems='center'>
                            <Center
                              mx='2'
                              mb='3'
                              pos='relative'
                              bgColor='white'
                              // boxSize='calc(min(25vh,25vw))'
                              boxSize={task.imageView ? '' : 'calc(min(25vh,25vw))'}
                              height={task.imageView && '30vh'}
                              width={task.imageView &&'35vw'}
                              padding={task.imageView?'8':''}
                              borderWidth='4px'
                              borderRadius='xl'
                              borderColor={
                                img.checked ? 'pink.500' : 'transparent'
                              }
                              className={
                                img.checked ? 'image image-clicked' : 'image-unselected'
                              }
                              onClick={() => onSelectedImageChange(img.url)}
                            >
                              {img.checked ? (
                                <Center
                                  pos='absolute'
                                  left='1'
                                  top='1'
                                  boxSize='5'
                                  bgColor='pink.500'
                                  borderRadius='full'
                                >
                                  <CheckIcon color='white' />
                                </Center>
                              ):(
                                <Center
                                pos='absolute'
                                left='1'
                                top='1'
                                boxSize='5'
                                bgColor='lightgray'
                                borderRadius='full'
                              >
                                <CheckIcon color='white' />
                              </Center>
                              )}
                              <Box
                                bgImage={apiBase + img.url}
                                bgRepeat='no-repeat'
                                bgSize='contain'
                                bgPos='center'
                                borderRadius='xl'
                                h='full'
                                w='full'
                                
                              />
                            </Center>
                            {task.id ===
                              '2bf15e4d-40fd-4412-a962-2213a50a5edf' && (
                              <div
                              className={`soundIcon ${isSoundPlaying[
                                i18n.language === 'se'
                                  ? apiBase + img.soundUrl[0]
                                  : i18n.language === 'de' 
                                  ? apiBase + img.soundUrl[2]
                                  : apiBase + img.soundUrl[1]
                              ] ? 'animateAudioIcon' : ''}`}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center', // Center horizontally
                                  alignItems: 'center',
                                  marginTop: '20px',
                                  // marginLeft: '75px',

                                  width: '45px',
                                  height: '45px',
                                  borderRadius: '50%',
                                  overflow: 'hidden',
                                  background: isSoundPlaying[
                                    i18n.language === 'se'
                                  ? apiBase + img.soundUrl[0]
                                  : i18n.language === 'de' 
                                  ? apiBase + img.soundUrl[2]
                                  : apiBase + img.soundUrl[1]
                                  ]
                                    ? 'white'
                                    : '#2a0f74',
                                }}
                              >
                                <a
                                  href='#'
                                  onClick={e => {
                                    e.preventDefault()
                                    playSound(
                                      i18n.language === 'se'
                                  ? apiBase + img.soundUrl[0]
                                  : i18n.language === 'de' 
                                  ? apiBase + img.soundUrl[2]
                                  : apiBase + img.soundUrl[1]
                                    )
                                  }}
                                >
                                  <svg
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    strokeWidth='1.5'
                                    stroke={
                                      isSoundPlaying[
                                        i18n.language === 'se'
                                  ? apiBase + img.soundUrl[0]
                                  : i18n.language === 'de' 
                                  ? apiBase + img.soundUrl[2]
                                  : apiBase + img.soundUrl[1]
                                      ]
                                        ? 'black'
                                        : 'currentColor'
                                    }
                                    width='24'
                                    height='24'
                                    color='black'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z'
                                    />
                                  </svg>
                                </a>
                              </div>
                            )}
                          </Flex>
                        </Center>
                      ))}
                    </Flex>
                  </Center>
                )}
                <TaskAdvice
                  instructions={task.taskInstructions}
                  marginTop='auto !important'
                />
              </VStack>
            </TabPanel>
            <TabPanel w='full' h='full' maxH='full'>
              <VStack w='full' h='full'>
                <Flex
                  h='full'
                  w='full'
                  flex='1'
                  align='center'
                  direction='column'
                >
                  <CheckboxAnswerGroup
                    options={task.answerOptions}
                    onChange={onAnswerChange}
                  />
                </Flex>
                <TaskAdvice
                  instructions={task.answerInstructions}
                  marginTop='auto !important'
                />
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Flex>
  )
}
