import React from 'react'
import { HStack } from '@chakra-ui/react'
import Dropdown from 'react-dropdown'

export default function DepartmentDropdownEn(props: {
  handleSelectDepartment: any
  handleNext: Function
  preschool: any
  placeHolder: string
  departmentId: any
}) {
  return (
    <HStack spacing='5' w='fit-content'>
      <Dropdown
        options={props.preschool?.departments?.map(department => ({
          value: department.id,
          label: department.name,
        }))}
        onChange={() => {
          props.handleSelectDepartment
          props.handleNext()
        }}
        value={
          props.preschool?.departments?.find(
            department => department.id === props.departmentId,
          )?.name
        }
        placeholder={props.placeHolder}
        controlClassName={`custom-control w-preschool-department ${props.departmentId ? '' : 'addError'}`}
        arrowClassName='custom-arrow'
        menuClassName='custom-menu'
        placeholderClassName='custom-placeholder'
      />
    </HStack>
  )
}
