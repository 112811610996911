import React, { ChangeEventHandler } from 'react'
import { HStack, Textarea, VStack, Text, Image } from '@chakra-ui/react'
import { HelpToolTip } from 'pages/Authenticated/FollowUp/HelpToolTip'
import { useTranslation } from 'react-i18next'
import CheckIconComment from '../../../../assets/images/Comments-Tick.svg'
interface CommentSectionProps {
  value: string
  label: string
  onChange: (newVal: string) => void
  helpLabel: string
  helpText: string
}

export const CommentSection = ({
  value,
  onChange,
  label,
  helpText,
  helpLabel,
}: CommentSectionProps) => {
  const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
    onChange(e.target.value)
  }
  const { t } = useTranslation()
  return (
    <VStack w='495px' justifyContent={'flex-start'} >
      <VStack w='full' alignItems={'center'} justifyContent='center'>
        <HStack mb={7}>
          <Image src={CheckIconComment} />
        <Text fontSize='32px' lineHeight='34px' >{t('Teacher_Comment_Title')}</Text>
        </HStack>
       
       <Text fontSize='16px' lineHeight='26px' pb={7} textAlign='center'>{t('Teacher_Comment_Description')}</Text>
        {/* <HelpToolTip
          title={helpLabel}
          body={helpText}
          h='full'
          display={'flex'}
          alignItems={'center'}
          imgSize={4}
        /> */}
      </VStack>
      <Textarea
        w='full'
        bgColor='white'
        color='black'
        value={value}
        onChange={handleOnChange}
        placeholder={t('Own_Notes_Placeholder')}
        resize='none'
        fontSize='14px'
      />
    </VStack>
  )
}
