/* eslint-disable camelcase */
import axios from 'axios'
import axiosAPIInstance, { getAxiosInstance } from './api'
import { client } from 'axios-oauth-client'
import { tokenUrl, clientId } from './config'
import jwtDecode from 'jwt-decode'
import { User } from 'data-models/user'
import {
  GoogleAuthProvider,
  SAMLAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'
import { auth } from './firebase'
import { cookies } from './cookies'

//const provider = new GoogleAuthProvider()
const provider = new SAMLAuthProvider('saml.avesta-idp')

const AuthService = {
  getProfile: () => axiosAPIInstance.get(`/profile`).then(r => r.data),

  login: ({ username, password }: { username: string; password: string }) => {
    return signInWithEmailAndPassword(auth, username, password)
      .then(async result => {
        const token = result?.user ? await result.user.getIdToken() : undefined

        if (!token) {
          throw new Error('No token returned.')
        }

        if (!AuthService.isAppUser(token)) {
          throw new Error('User is not a Myran user.')
        }

        const tokens = {
          access_token: token,
          refresh_token: result.user.refreshToken,
        }
        // eslint-disable-next-line camelcase
        return tokens
      })
      .catch(err => {
        throw err
      })
  },

  loginWithCustomProvider: () =>
    signInWithPopup(auth, provider)
      .then(async result => {
        const token = await result?.user.getIdToken()

        if (!token) {
          console.log('No token returned.')
          throw new Error('No token returned.')
        }

        if (!AuthService.isAppUser(token)) {
          console.log('User is not a Myran user.')
          throw new Error('User is not a Myran user.')
        }

        const tokens = {
          access_token: token,
          refresh_token: result.user.refreshToken,
        }
        // eslint-disable-next-line camelcase
        return tokens
      })
      .catch(err => {
        throw err
      }),


  registerUsingCustomProvider: () =>
    signInWithPopup(auth, provider)
      .then(async result => {
        const token = await result?.user.getIdToken()

        const tokenInfo = jwtDecode(token || '') as any
        const { name, email } = tokenInfo

        if (!token) {
          console.log('No token returned.')
          throw new Error('No token returned.')
        }

        // eslint-disable-next-line camelcase
        return {
          name,
          email,
        }
      })
      .catch(err => {
        throw err
      }),

  logout: () => {
    cookies.remove('token')
    cookies.remove('refresh_token')
    cookies.remove('user')
    window.location.assign(`/login`)
  },

  isAppUser: (token: string | undefined) => {
    const tokenUser = AuthService.getUserFromAccessToken(token)
    return (
      tokenUser &&
      tokenUser.role &&
      tokenUser.role.startsWith('Myran.') &&
      tokenUser.organizationId
    )
  },

  getUserFromAccessToken: (accessToken: string | undefined) => {
    const tokeninfo = jwtDecode(accessToken || '') as any
    if (tokeninfo) {
      const user: User = {
        name: tokeninfo.name,
        id: tokeninfo.sub,
        email: tokeninfo.email,
        role: tokeninfo.role,
        organizationId: tokeninfo.organizationId,
        preschoolIds: tokeninfo.preschoolIds,
      }
      return user
    }
    return null
  },

  sendPasswordResetMail: (x: { email: string; message: string }) =>
    axiosAPIInstance.post(`/forgot-password`, x),

  storeTokens: ({
    access_token,
    refresh_token,
  }: {
    access_token: string
    refresh_token: string
  }) => {
    cookies.set('token', access_token)
    cookies.set('refresh_token', refresh_token)
  },

  getToken: () => cookies.get('token'),

  getRefreshToken: () => cookies.get('refresh_token'),

  refreshToken: () =>
    client(axios.create(), {
      url: tokenUrl,
      grant_type: 'refresh_token',
      client_id: clientId,
      refresh_token: AuthService.getRefreshToken(),
    })()
      .then((data: { access_token: string; refresh_token: string }) => {
        AuthService.storeTokens(data)
        return data
      })
      .catch(() => {
        cookies.remove('token')
        cookies.remove('refresh_token')
        cookies.remove('user')
        window.location.reload()
      }),

  activateUser: async (inviteCode: string, type: string, token: string) => {
    const basicAuth = 'Basic ' + btoa(`:${inviteCode}`)
    const user = AuthService.getUserFromAccessToken(token)
    const axiosInstance = getAxiosInstance()
    const result = await axiosInstance.post(
      `/users/activate/` + inviteCode,
      { ...user, type },
      {
        headers: { Authorization: basicAuth },
      },
    )
    return result.data
  },
  clearCookie: () => {
    cookies.remove('token')
    cookies.remove('refresh_token')
    cookies.remove('user')
  },
  getUser: () => {
    const tokeninfo = jwtDecode(AuthService.getToken() || '') as any
    if (tokeninfo) {
      const user: User = {
        name: tokeninfo.name,
        id: tokeninfo.sub,
        email: tokeninfo.email,
        role: tokeninfo.role,
        preschool: '',
        organizationId: tokeninfo.organizationId,
        preschoolIds: tokeninfo.preschoolIds,
        username: tokeninfo.username,
      }
      return user
    }
    return null
  },
  cacheUser: (user) => {
    // Convert the user object to a JSON string
    const userJSON = JSON.stringify(user);

    // Set the cookie
    cookies.set('user', userJSON);

  },

  getCachedUser: () => JSON.parse(cookies.get('user') || '{}') as User,
}

export { AuthService }
