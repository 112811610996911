import React from 'react'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { HStack, VStack, Text, Box, Center } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { TaskService } from 'services/taskService'
import CheckIcon from 'common/CheckIcon'
import { padStart } from 'lodash-es'

export default function Navbar() {
  const { tasks, currentTask, dispatch } = useFollowUp()
  const location = useLocation()

  return location.pathname === '/tasks' && tasks.length ? (
    <HStack spacing='10' pl='20'>
      {tasks.map(q => {
        const isCurrentTask = currentTask.id === q.id
        const isAnswered = TaskService.taskIsAnswered(q)
        const qNumber = padStart(q.orderIndex.toString(), 2, '0')
        const navToTask = () =>
          dispatch({ type: QUESTION_ACTIONS.NAV_TO_QUESTION, payload: q })

        if (isCurrentTask)
          return (
            <VStack
              key={q.id}
              fontFamily='Catamaran'
              spacing='1'
              fontWeight='bold'
              fontSize='2xl'
              cursor='pointer'
            >
              <Text>{qNumber}</Text>
              <Box
                px='4'
                h='4px'
                bgColor='pink.500'
                w='full'
                borderRadius='md'
              />
            </VStack>
          )
        else if (isAnswered)
          return (
            <Center
              key={q.id}
              borderRadius='full'
              bgColor='pink.500'
              onClick={navToTask}
              boxSize='6'
              cursor='pointer'
            >
              <CheckIcon />
            </Center>
          )
        else
          return (
            <Text
              key={q.id}
              fontFamily='Catamaran'
              onClick={navToTask}
              cursor='pointer'
            >
              {qNumber}
            </Text>
          )
      })}
    </HStack>
  ) : (
    <></>
  )
}
