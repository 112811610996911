import { useFollowUp } from 'providers/FollowUpProvider'
import { useHistory } from 'react-router-dom'

export const useTeacherComment = () => {
  const { ownComment, groupComment, setGroupComment, setOwnComment } =
    useFollowUp()

  const history = useHistory()

  const onClose = () => {
    history.push('/tasks')
  }

  const onConfirm = () => {
    history.push('/follow-up-finish')
  }

  return {
    ownComment,
    groupComment,
    setOwnComment,
    setGroupComment,
    onClose,
    onConfirm,
  }
}
