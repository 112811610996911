import React from 'react'
import {
  Icon,
  Text,
  HStack,
  VStack,
  Drawer,
  Button,
  DrawerBody,
  IconButton,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Image,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

import { SectionDivider } from './SideBar.layout'
import { usePreschool } from 'providers/PreschoolProvider'
import { useUser } from 'providers/UserProvider'
import { AvatarIcon } from 'common/AvatarIcon'
import { UserRoleToLabelMapping, UserRoleToLabelMappingen,UserRoleToLabelMappingDe } from 'data-models'
import { SchoolIcon } from 'common/LibraryIcon'
import { ChartpieIcon } from 'common/ChartpieIcon'
import { adminUrl } from 'services/config'
import { LogoutIcon } from 'common/LogoutIcon'
import { ConfirmationModal } from 'common/Confirmation'
import { AuthService } from 'services/authService'
import { UserManualIcon } from 'common/UserManualIcon'
import { useDownloadFile } from 'services/fileService'
import { useTranslation } from 'react-i18next'
import { FollowupDetailsSideBar } from './FollowupDetailsSideBar'
import { Feedback } from 'common/Feedback'
import { CommentIcon } from 'common/CommentIcon'
import { useLocation } from 'react-router-dom'
import  ReleaseImage  from '../../../../assets/images/release-notes.png'

export const UserDetailsSideBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isConfirmLogoutOpen,
    onOpen: onConfirmLogoutOpen,
    onClose: onConfirmLogoutClose,
  } = useDisclosure()

  const { user } = useUser()
  const { preschools } = usePreschool()
  const { t, i18n } = useTranslation()
  const pathsDisplayingHeader = ['/tasks', '/child-information']
  const { pathname } = useLocation()
  const associatedPreschools = preschools?.filter(preschool =>
    user?.preschoolIds?.includes(`${preschool?.id}`),
  )

  const goToAnalytics = () => {
    window.open(`${adminUrl}/statistics`, '_blank')
  }
  const handleLogout = () => AuthService.logout()
  const { resourceURL: userManualDownloadEnglishURL } = useDownloadFile({
    path: '/assets/Myran-UserGuide-EN.pdf',
  })

  const { resourceURL: userManualDownloadSwedishURL } = useDownloadFile({
    path: '/assets/Myran-UserGuide-SE.pdf',
  })

  function getMunicipalityNames(preschools: any[]) {
    if (preschools) {
      return preschools
        .map(preschool => preschool.municipalityName)
        .filter(
          (municipalityName): municipalityName is string =>
            municipalityName !== undefined,
        )
    }
  }

  const organization =
    associatedPreschools && associatedPreschools.length > 0
      ? associatedPreschools[0].municipalityName || ''
      : ''
  return (
    <>
      <IconButton
        aria-label='menu-button'
        variant='ghost2'
        _focus={{
          boxShadow: 'none',
        }}
        _hover={{
          color: 'black',
          opacity: 0.6,
        }}
        onClick={onOpen}
        icon={
          <Icon
            _focus={{
              color: 'red',
            }}
            _hover={{
              color: 'red',
            }}
            as={() => <AvatarIcon size='40px' />}
          />
        }
      />
      <Drawer placement='right' isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          zIndex={100}
          pos='relative'
          bgColor='white'
          maxW='360px'
          borderLeftRadius='20px'
        >
          <DrawerHeader
            pb='4'
            px='0'
            paddingTop='30px'
            borderBottom='1px solid #ECEAF0'
          >
            <HStack
              px='6'
              w='full'
              h='full'
              alignItems='center'
              justifyContent='space-between'
            >
              <Text textColor='black' fontWeight='bold'>
                {t('User_Info_Title')}
              </Text>
              <DrawerCloseButton top='unset' borderRadius='full' boxSize='10'>
                <CloseIcon color='black' />
              </DrawerCloseButton>
            </HStack>
          </DrawerHeader>
          <DrawerBody px='6' py='6'>
            <VStack w='full' spacing='2'>
              <HStack w='full' pb='1' spacing='3' mr={7}>
                <AvatarIcon color='#999999' size='48px' />
                <VStack justify='flex-start'>
                  <Text color='black' fontSize='md' fontWeight='600' mt={5}>
                    {user.name}
                  </Text>
                  <Text color='black' fontSize='sm' w='full'>
                    {i18n.language === 'en'
                      ? UserRoleToLabelMappingen[user.role]
                      : i18n.language === 'de' 
                      ?
                      UserRoleToLabelMappingDe[user.role]
                      : UserRoleToLabelMapping[user.role]}
                  </Text>
                </VStack>
              </HStack>
              <Text
                paddingLeft='47px'
                w='full'
                color='darkIndigo'
                fontSize={14}
              >
                {user.email}
              </Text>
            </VStack>
            <SectionDivider />
            {UserRoleToLabelMapping[user.role] === 'Admin' ? (
              <VStack w='full' spacing='2'>
                <VStack w='full' spacing='4'>
                  <HStack w='full' spacing='2'>
                    <SchoolIcon />
                    <Text
                      w='full'
                      color='black'
                      fontSize='sm'
                      fontWeight='600'
                      paddingLeft='18px'
                    >
                      {t('Associated_organization')}
                    </Text>
                  </HStack>{' '}
                  <VStack w='full' spacing='2' justifyContent='flex-start'>
                    <Text
                      paddingLeft='47px'
                      w='full'
                      fontSize='sm'
                      color='#666666'
                    >
                      {organization}
                    </Text>
                  </VStack>
                  {pathsDisplayingHeader.includes(pathname) && (
                    <FollowupDetailsSideBar />
                  )}
                </VStack>
              </VStack>
            ) : (
              <VStack w='full' spacing='2'>
                <VStack w='full' spacing='4'>
                  <HStack w='full' spacing='2'>
                    <SchoolIcon />
                    <Text
                      w='full'
                      color='black'
                      fontSize='sm'
                      fontWeight='600'
                      paddingLeft='18px'
                    >
                      {t('Associated_Schools')}
                    </Text>
                  </HStack>{' '}
                  <VStack w='full' spacing='2' justifyContent='flex-start'>
                    {associatedPreschools.map(preschool => (
                      <Text
                        paddingLeft='47px'
                        w='full'
                        key={preschool.id}
                        fontSize='sm'
                        color='#666666'
                      >
                        {preschool.name}
                      </Text>
                    ))}
                  </VStack>
                  {pathsDisplayingHeader.includes(pathname) && (
                    <FollowupDetailsSideBar />
                  )}
                </VStack>
              </VStack>
            )}

            <SectionDivider />
            <HStack w='fit-content' cursor='pointer' onClick={goToAnalytics}>
              <ChartpieIcon color='#2a0f74' />
              <Text fontSize='sm' color='darkIndigo' paddingLeft='18px'>
                {t('Analytics_Link')}
              </Text>
            </HStack>
            <SectionDivider />
            <HStack w='fit-content' cursor='pointer'>
              <UserManualIcon color='#2a0f74' />
              <a
                href={
                  i18n.language === 'se'
                    ? userManualDownloadSwedishURL
                    : userManualDownloadEnglishURL
                }
                download={
                  i18n.language === 'se'
                    ? 'Myran-UserGuide-SE.pdf'
                    : 'Myran-UserGuide-EN.pdf'
                }
                style={{
                  color: '#2a0f74',
                  fontSize: '14px',
                  paddingLeft: '18px',
                }}
              >
                {t('DownloadManual_Link')}
              </a>
            </HStack>
            <SectionDivider />
            <Feedback>
              <HStack cursor='pointer' w='full'>
                <CommentIcon />
                <Text paddingLeft='18px' color='darkIndigo' fontSize={14}>
                  {t('Feedback_Link')}
                </Text>
              </HStack>
            </Feedback>
            <SectionDivider />
            <HStack w='fit-content' cursor='pointer'>
            <UserManualIcon color='#2a0f74' />
              <a href='/release-notes' target="_blank" >
             
              <Text paddingLeft='18px' color='darkIndigo' fontSize={14}>{t('Release_Notes')}</Text>
              </a>
            </HStack>
          </DrawerBody>
          <DrawerFooter
            p='6'
            w='full'
            justifyContent='flex-end'
            borderTop='1px solid #ECEAF0'
          >
            <Button
              p='5'
              id='logoutButtonId'
              fontSize='sm'
              color='white'
              borderRadius='8px'
              onClick={onConfirmLogoutOpen}
              rightIcon={<LogoutIcon color='white' />}
            >
              {t('MainHeader_Logout')}
            </Button>

            <ConfirmationModal
              isLoading={false}
              isOpen={isConfirmLogoutOpen}
              onClose={onConfirmLogoutClose}
              onConfirmCallback={handleLogout}
              header={
                <Text color={'mainBlue.900'}>{t('MainHeader_Logout')}</Text>
              }
              body={
                <Text color={'black'}>{t('Logout_Confirmation_Question')}</Text>
              }
              cancelText={t('No_Continue_Button_Text')}
              confirmText={t('Yes_LogOut_Button_Text')}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
