/* eslint-disable react/jsx-key */
import React from 'react'
import {
  Text,
  Grid,
  Flex,
  HStack,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react'
import { CheckboxAnswer } from 'data-models/task-models'
import CheckboxCircle from './CheckboxCircle'
import { useTranslation } from 'react-i18next'
export default function CheckboxAnswerGroup(props: {
  options: CheckboxAnswer[]
  onChange: (state: CheckboxAnswer[]) => void
}) {
  function onSelectedChange(x: CheckboxAnswer['id']) {
    const newOptionsState = props.options.map(o => ({
      ...o,
      checked: o.id === x,
    }))

    props.onChange(newOptionsState)
  }

  const [isSmallScreen] = useMediaQuery('(max-height: 900px)')
  const { i18n } = useTranslation()
  return (
    <Flex w='full' h='full' align='center' justify='center'>
      <Grid
        w={isSmallScreen ? '50%' : '50%'}
        h='fit-content'
        maxW='100%'
        minH='0'
        maxH={isSmallScreen ? '35vh' : 'unset'}
        zIndex='1000'
        // align='center'
        // justify='center'
        gridGap='3'
        gridAutoFlow='column'
        gridTemplateColumns={
          isSmallScreen ? 'repeat(1, 1fr)' : 'repeat(1, 1fr)'
        }
        gridTemplateRows={isSmallScreen ? 'repeat(5, 1fr)' : 'repeat(5, 1fr)'}
      >
        {props.options.map(
          o =>
            o.styleClass !== 'childParticipate' && (
              <GridItem
                w='full'
                display='flex'
                justifyContent='center'
                textAlign='left'
              >
                <HStack
                  p='2'
                  w='full'
                  key={o.id}
                  minH='0'
                  cursor='pointer'
                  border='1px'
                  alignItems='center'
                  borderColor='white'
                  borderRadius='xl'
                  bgColor={o.checked ? 'pink.500' : 'transparent'}
                  onClick={() => onSelectedChange(o.id)}
                  _hover={
                    window.matchMedia('(hover: hover)').matches
                      ? { opacity: '60%', background: '#5A4FD3' }
                      : {}
                  }
                  boxShadow={o.checked ? '0px 0px 8px 0px indigo' : 'none'}
                >
                  <CheckboxCircle checked={o.checked} />
                  <Text
                    lineHeight='1.5'
                    fontSize={isSmallScreen ? '13px' : 'md'}
                  >
                    {i18n.language === 'en' ? o.text[1] : i18n.language === 'se' ? o.text[0] : o.text[2]}
                  </Text>
                </HStack>
              </GridItem>
            ),
        )}
      </Grid>
    </Flex>
  )
}
