import axiosAPIInstance from './api'
import { FeedbackType, FeedbackTypeLabel } from 'data-models'

interface SendFeedbackProps {
  type: FeedbackType
  feedback: string
}

export const sendFeeback = async ({
  type,
  feedback,
}: SendFeedbackProps): Promise<void> => {
  const res = await axiosAPIInstance.post(`/emails/feedback`, {
    feedback,
    type: FeedbackTypeLabel[type],
  })

  return res.data
}
