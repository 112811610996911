import React, { useState } from 'react'
import {
  Text,
  Image,
  Input,
  Button,
  useToast,
  InputGroup,
  InputRightElement,
  Divider,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { HStack, VStack } from '@chakra-ui/layout'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AuthService } from 'services/authService'
import { errorToast } from 'common/utils'
import { useUser } from 'providers/UserProvider'
import hidePswd from '../../assets/images/ico-hide-pwd.svg'
import showPswd from '../../assets/images/ico-show-pwd.svg'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'

export default function Login() {
  const [passwordShown, setPasswordShown] = useState(false)
  const [eyeShow, seteyeShown] = useState(showPswd)
  const { t } = useTranslation()
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
    seteyeShown(eyeShow === showPswd ? hidePswd : showPswd)
  }
  const [processing, setProcessing] = useState(false)
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(i18n.t('Invalid_Email'))
      .required(i18n.t('Email_Required')),
    password: Yup.string().required(i18n.t('Password_Required')),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })
  const toast = useToast()
  const { setUser } = useUser()
  const onSubmit = (data: any) => {
    setProcessing(true)
    AuthService.login({
      username: data.email,
      password: data.password,
    })
      .then(payload => {
        AuthService.storeTokens(payload)
        const usr = AuthService.getUser()
        if (usr) setUser(usr)
      })
      .catch(() => {
        toast(errorToast(i18n.t('Invalid_email_or_password')))
      })
      .finally(() => {
        setProcessing(false)
      })
  }
  const history = useHistory()
  const handleClick = () => {
    history.push('/login/sso')
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <VStack spacing='12' alignItems='stretch'>
        <VStack spacing='5' alignItems='stretch'>
          <VStack alignItems='stretch'>
            {/* <HStack alignItems='baseline'>
              <Text fontSize='4xl' fontWeight='bold'>
                {t('Enter_Email_Text')}
              </Text>
            </HStack> */}
            <HStack justifyContent='space-between'>
              <Text fontWeight='light' color='white'>
                {errors.email?.message}
              </Text>
            </HStack>
            <Input
              h={50}
              backgroundColor={'white'}
              color='black'
              placeholder={t('Login_Email_PlaceHolder')}
              _placeholder={{ color: 'black' }}
              className='remove-input-auto-fill'
              {...register('email')}
            />
          </VStack>

          <VStack alignItems='stretch'>
            <HStack justifyContent='space-between'>
              <Text fontWeight='light' color='white'>
                {errors.password?.message}
              </Text>
            </HStack>
            <InputGroup>
              <InputRightElement>
                <Image
                  src={eyeShow}
                  onClick={togglePassword}
                  paddingRight='22px'
                  style={{ cursor: 'pointer' }}
                />
              </InputRightElement>
              <Input
                h={50}
                backgroundColor={'white'}
                color='black'
                placeholder={t('Login_Pass_PlaceHolder')}
                _placeholder={{ color: 'black' }}
                className='remove-input-auto-fill'
                type={passwordShown ? 'text' : 'password'}
                {...register('password')}
              />
            </InputGroup>
          </VStack>
        </VStack>
        <HStack height='5px' justifyContent='space-between' mt={-10}>
          {/* <NavLink to='/forgot-password'>
            <Button type='button' variant='ghost'>
              Glömt ditt lösenord?
            </Button>
          </NavLink> */}
          <text></text>
          <Button
            id='loginButtonId'
            h={10}
            fontSize='14px'
            type='submit'
            disabled={processing}
            rightIcon={<ArrowForwardIcon boxSize='4' />}
          >
            {t('Login_Button')}
          </Button>
        </HStack>
        <Divider color='indigoBlue' orientation='horizontal' />
        <Button h={10} fontSize='14px' type='submit' onClick={handleClick}>
          {t('Login_With_SSO')}
        </Button>
      </VStack>
    </form>
  )
}
