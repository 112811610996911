import { useEffect, useState } from 'react'

import axiosAPIInstance from './api'

import {
  CreateOrganizationDTO,
  Organization,
  OrganizationList,
  UpdateOrganizationDTO,
} from 'data-models'

export const useOrganizationService = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([])

  const getOrganizations = () =>
    axiosAPIInstance
      .get(`/organizations`)
      .then(({ data }) => data as Organization[])

  const refresh = async () => {
    const response = await getOrganizations()
    if (response && response.length > 0) {
      setOrganizations(response)
    }
  }

  useEffect(() => {
    if (organizations.length === 0) {
      refresh()
    }
  }, [])

  return {
    organizations,
    refresh,
  }
}

export const useOrganizationDetails = (organizationId?: string) => {
  const [organization, setOrganization] = useState<Organization>()
  const [loading, setLoading] = useState(false)

  const getOrganizationDetails = (organizationId?: string) => {
    setLoading(true)

    return axiosAPIInstance
      .get(`/organizations/${organizationId}`)
      .then(({ data }) => {
        setLoading(false)
        return data as Organization
      })
  }

  const refresh = async () => {
    const response = await getOrganizationDetails(organizationId)
    if (response) {
      setOrganization(response)
    }
  }

  useEffect(() => {
    if (organizationId) {
      refresh()
    }
  }, [organizationId])

  return {
    loading,
    organization,
    refresh,
  }
}

export const getOrganizationList = async (): Promise<OrganizationList> => {
  const res = await axiosAPIInstance.get(`/organizations/list`)

  return res.data
}

export const deleteOrganization = async (
  organizationId: string,
): Promise<void> => {
  const res = await axiosAPIInstance.delete(`/organizations/${organizationId}`)

  return res.data
}

export const createOrganization = async (
  values: CreateOrganizationDTO,
): Promise<void> => {
  const res = await axiosAPIInstance.post(`/organizations`, { ...values })

  return res.data
}

interface UpdateOrganizationProps {
  values: UpdateOrganizationDTO
  organizationId?: string
}

export const updateOrganization = async ({
  values,
  organizationId,
}: UpdateOrganizationProps): Promise<void> => {
  const res = await axiosAPIInstance.put(`/organizations/${organizationId}`, {
    ...values,
  })

  return res.data
}
