import { Department } from './department'

export interface Preschool {
  municipalityName: string
  id: string
  name: string
  departments: Department[]
}

export const emptyPreschool: Preschool = {
  municipalityName: '',
  id: '',
  name: '',
  departments: [],
}
