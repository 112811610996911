import React, { PropsWithChildren } from 'react'
import { Tag, TagLabel } from '@chakra-ui/tag'

export default function TaskTag(props: PropsWithChildren<{}>) {
  return (
    <Tag borderRadius='full' bg='rgba(255,255,255,0.1)' p='2'>
      <TagLabel fontSize='xs'>{props.children}</TagLabel>
    </Tag>
  )
}
