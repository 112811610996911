import React from 'react'

interface ChartpieIconProps {
  color?: string
  size?: string | number
}

export const ChartpieIcon = ({
  color = '#2A0F74',
  size = '24px',
}: ChartpieIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    color={color}
    fill={'none'}
  >
    <path
      d='M6.39229 4.95892C7.71821 3.90301 9.31534 3.24294 10.9998 3.05469V12.9997H20.9448C20.7566 14.6842 20.0965 16.2813 19.0406 17.6072C17.9847 18.9332 16.5759 19.934 14.9762 20.4945C13.3766 21.0551 11.6512 21.1526 9.99861 20.7758C8.34603 20.399 6.83341 19.5632 5.63487 18.3647C4.43632 17.1661 3.60056 15.6535 3.22376 14.0009C2.84696 12.3483 2.94444 10.6229 3.50499 9.02331C4.06554 7.42368 5.06637 6.01484 6.39229 4.95892Z'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15 8.99972H20.488C20.0391 7.73433 19.3135 6.58506 18.3641 5.63566C17.4147 4.68625 16.2654 3.96065 15 3.51172V8.99972Z'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
