import React from 'react'
import { ImagePlacementTask } from 'data-models/task-models'
import { Flex, Image } from '@chakra-ui/react'
import ImagePlacementPoint from './ImagePlacementPoint'
import { useFollowUp } from 'providers/FollowUpProvider'
import { apiBase } from 'services/config'

export default function ImagePlacementBase() {
  const { currentTask } = useFollowUp()
  const task = currentTask as ImagePlacementTask

  return (
    <Flex pos='relative' zIndex='0' display='flex' justify='center'>
      <Image src={apiBase + task.imageUrl} pos='relative' zIndex='-1' />

      {task.placementPoints.map(pp => {
        const placedImage = task.answerOptions.find(
          a => a.placedAtPoint === pp.id,
        )

        return <ImagePlacementPoint key={pp.id} pp={pp} pi={placedImage} />
      })}
    </Flex>
  )
}
