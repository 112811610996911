import React from 'react'

export default function CheckIcon({
  color,
  size = '16',
}: {
  color?: string
  size?: number | string
}) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.333 8.667 6 11.333l6.667-6.666'
        stroke={color || '#2a0f74'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
