/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import {
  TaskType,
  ImageSelectionTask,
  ImageSelectionAnswer,
} from 'data-models/task-models'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import {
  Tab,
  Tabs,
  Flex,
  Image,
  VStack,
  HStack,
  Center,
  TabList,
  TabPanel,
  TabPanels,
  AspectRatio,
  // useBreakpoint,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { TaskAdvice } from './Common'
import CheckIcon from 'common/CheckIcon'
import { apiBase } from 'services/config'
import VideoPlayer from './VideoTask/VideoPlayer'
import { TaskHeading } from './Common/TaskHeading'
import { useFullscreen } from 'providers/FullscreenProvider'

export default function ImageSelectionTaskCmp() {
  const { isFullscreen } = useFullscreen()
  const { t, i18n } = useTranslation()
  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as ImageSelectionTask
  const [isSelected,setIsSelected] = useState(false)


//answer options animate when page is rerendered, so to prevent it the clases are removed when rendering.
  useEffect(()=>{
    clearAnimation();
  },[])

  const clearAnimation = () => {
    task.answerOptions.forEach((item)=>{
      const imageOptionsUnSelected = document.getElementsByClassName('image-unselected');
      Array.from(imageOptionsUnSelected).forEach(imageOption => {
        // Remove the 'image-unselected' class from each element
        imageOption.classList.remove('image-unselected');
      });
    if(item.checked){
      const imageOptionsSelected = document.getElementsByClassName('image-clicked');
      Array.from(imageOptionsSelected).forEach(imageOption => {
        // Remove the 'image-clicked' class from each element
        imageOption.classList.remove('image-clicked');
      });
     
    }
  })
  }

  function onSelectedChange(changed: ImageSelectionAnswer['id']) {
    const newOptionsState = task.answerOptions.map(ao => ({
      ...ao,
      checked: ao.id === changed ? !ao.checked : ao.checked,
    }))

    const checkImagesSelected = newOptionsState.filter(
      x => x.checked && x.type === TaskType.ImageSelection,
    )
    newOptionsState.map(obj => {
      if (
        checkImagesSelected.length > 0 &&
        obj.type === TaskType.Checkbox &&
        obj.checked
      ) {
        obj.checked = false
      }
      return obj
    })
    dispatch({
      type: QUESTION_ACTIONS.UPDATE_ANSWERS,
      payload: newOptionsState,
    })
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }
  const [isSoundPlaying, setIsSoundPlaying] = useState({})
  const playSound = soundUrl => {
    const audio = new Audio(soundUrl)
    audio.play()
    setIsSoundPlaying(prevState => ({
      ...prevState,
      [soundUrl]: true,
    }))
    audio.onended = () =>
      setIsSoundPlaying(prevState => {
        const newState = { ...prevState }
        delete newState[soundUrl] // Remove the soundUrl from state
        return newState
      })
  }
  // const breakpoint = useBreakpoint() || '2xl'

  // const computeWidthByBreakpoint = () => {
  //   switch (breakpoint) {
  //     case 'lg':
  //       return '80%'
  //     case 'md':
  //     case 'sm':
  //       return '100%'
  //     default:
  //       return '50%'
  //   }
  // }

  const onChangeTab = (index: number) => {
    clearAnimation();
    dispatch({ type: QUESTION_ACTIONS.SET_CURRENT_STEP, payload: index })
  }


  
     // check what language video is selected in the dropdown
  const [selectedVideoLanguage,setSelectedVideoLanguage] = useState('swedish');

  useEffect(() => {
  }, [selectedVideoLanguage]);

  const handleVideoLanguageChange = (lang) => {
    setSelectedVideoLanguage(lang);
  };

  const getVideoSrc = (url) => {
    // for video tasks, when language other than swedish is selected, we remove -SE from the url and replace it with -EN.
   // const urlParts = task.videoUrl.split('-');
   let languageSuffix = '';
   switch (selectedVideoLanguage) {
     case 'arabic':
       languageSuffix = 'AR'
       break;
       case 'danish':
         languageSuffix = 'DA'
         break;
         case 'german':
       languageSuffix = 'DE'
       break;
       case 'english':
       languageSuffix = 'EN'
       break;
       case 'finnish':
        languageSuffix= 'FI'
        break;
        case 'french':
        languageSuffix= 'FR'
        break;
        case 'norwegian':
        languageSuffix= 'NO'
        break;
        case 'italian':
        languageSuffix= 'IT'
        break;
     default:
       languageSuffix ='SE'
       break;
   }
  
   const hyphenIndex = url.lastIndexOf('-');
   const baseUrl = url.substring(0, hyphenIndex);
   return `${apiBase}${baseUrl}-${languageSuffix}.mp4`;
 // }
};

  return (
    <VStack pos='relative' h='full' w='full' p='5'>
      <TaskHeading
        description={task.description}
        subtitle={task.subtitle}
        headingTitle={task?.title}
      />

      <Tabs
        h='full'
        w='full'
        pos='relative'
        variant='unstyled'
        onChange={onChangeTab}
        index={currentTask.step}
      >
        <TabList pb='4' pt='3'>
          <Flex
            h='0'
            w='full'
            pos='absolute'
            alignSelf='center'
            justifySelf='center'
            borderTop='1px solid #2A0F74'
            zIndex={0}
          />
          <Tab
            px={4}
            py={2}
            mr={6}
            zIndex={1}
            border='1px solid #2A0F74'
            fontSize='xs'
            borderRadius='8px'
            bgColor='#6c40f6'
            _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
            _focus={{ boxShadow: 'none' }}
          >
            <span style={{ opacity: 0.5, paddingRight: '10px' }}>
              {t('Step')} 1
            </span>
            {t('Explain_Scenario_Title')}
          </Tab>
          <Tab
            px={4}
            py={2}
            zIndex={10}
            border='1px solid #2A0F74'
            bgColor='#6C40F6'
            fontSize='xs'
            borderRadius='8px'
            _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
            _focus={{ boxShadow: 'none' }}
          >
            <span style={{ opacity: 0.5, paddingRight: '10px' }}>
              {t('Step')} 2
            </span>
            {t('Mark_All_Options_Text')}
          </Tab>
        </TabList>
        {task.id === '32133305-db19-43fa-96c8-54aca833712f' ? (
          <TabPanels h='full' w='full' py={0}>
            <TabPanel h='full' w='full'>
              <Flex pb='12' h='full' w='full' justify='center' align='center'>
                {task.imageUrls?.map(url => (
                  <AspectRatio key={url} ratio={10} h='full' w='full'>
                    <Image
                      src={apiBase + url}
                      borderRadius='xl'
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  </AspectRatio>
                ))}
                {task.videoUrls?.map(url => (
                  <Center key={url} w='full' h='full'>
                    <VideoPlayer
                      isFullscreen={isFullscreen}
                      videoSrc={getVideoSrc(url)}
                      onVideoLanguageChange = {handleVideoLanguageChange}
                    />
                  </Center>
                ))}
              </Flex>
            </TabPanel>
            <TabPanel w='full' h='full' maxH='full'>
              <HStack
                w='full'
                h='90%'
                minH={0}
                align='center'
                spacing='5'
                justify='center'
              >
                {task.answerOptions.map(
                  ao =>
                    ao.type === TaskType.ImageSelection && (
                      <VStack align='center' justify='center' h='60%' w='full'>
                        <Center
                         key={ao.id}
                          w='full'
                          h='full'
                          pos='relative'
                          mt={-55}
                          bgColor='white'
                          borderRadius='xl'
                          borderWidth='4px'
                          mb={1}
                          borderColor={ao.checked ? 'pink.500' : 'transparent'}
                          onClick={() => onSelectedChange(ao.id)}
                          className={
                            ao.checked ? 'image image-clicked' : 'image-unselected'
                          }
                        >
                          {ao.checked ? (
                            <Center
                              pos='absolute'
                              left='1'
                              boxSize='5'
                              bgColor='pink.500'
                              borderRadius='full'
                              top='1'
                            >
                              <CheckIcon color='white' />
                            </Center>
                          ) : (
                            <Center
                              pos='absolute'
                              left='1'
                              boxSize='5'
                              bgColor='lightgray'
                              borderRadius='full'
                              top='1'
                            >
                              <CheckIcon color='white' />
                            </Center>
                          )}
                          <Image
                            src={apiBase + ao.imageUrl}
                            borderRadius='xl'
                            maxH='100%'
                            w='100%'
                            h='100%'
                            fit='contain'
                            maxW='100%'
                          />
                        </Center>

                        <div
                         className={`soundIcon ${isSoundPlaying[
                          i18n.language === 'se'
                            ? apiBase + ao.soundUrl[0]
                            : i18n.language === 'de'
                            ? apiBase + ao.soundUrl[2]
                            : apiBase + ao.soundUrl[1]
                        ] ? 'animateAudioIcon' : ''}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center',
                            marginTop: '10px',
                            //   marginLeft: '30px',
                            width: '45px',
                            height: '45px',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            background: isSoundPlaying[
                              i18n.language === 'se'
                            ? apiBase + ao.soundUrl[0]
                            : i18n.language === 'de'
                            ? apiBase + ao.soundUrl[2]
                            : apiBase + ao.soundUrl[1]
                            ]
                              ? 'white'
                              : '#2a0f74',
                          }}
                        
                        >
                          <a
                            href='#'
                            onClick={e => {
                              e.preventDefault()
                              playSound(
                                i18n.language === 'se'
                            ? apiBase + ao.soundUrl[0]
                            : i18n.language === 'de'
                            ? apiBase + ao.soundUrl[2]
                            : apiBase + ao.soundUrl[1]
                              )
                            }}
                          >
                            <svg
                              viewBox='0 0 24 24'
                              fill='none'
                              strokeWidth='1.5'
                              stroke={
                                isSoundPlaying[
                                  i18n.language === 'se'
                                  ? apiBase + ao.soundUrl[0]
                                  : i18n.language === 'de'
                                  ? apiBase + ao.soundUrl[2]
                                  : apiBase + ao.soundUrl[1]
                                ]
                                  ? 'black'
                                  : 'currentColor'
                              }
                              width='24'
                              height='24'
                              color='black'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z'
                              />
                            </svg>
                          </a>
                        </div>
                      </VStack>
                    ),
                )}
              </HStack>
            </TabPanel>
          </TabPanels>
        ) : (
          <TabPanels h='full' w='full' py={0}>
            <TabPanel h='full' w='full'>
              <Flex pb='12' h='full' w='full' justify='center' align='center'>
                {task.imageUrls?.map(url => (
                  <AspectRatio key={url} ratio={10} h='full' w='full'>
                    <Image
                      src={apiBase + url}
                      borderRadius='xl'
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  </AspectRatio>
                ))}
                {task.videoUrls?.map(url => (
                  <Center key={url} w='full' h='full'>
                    <VideoPlayer
                      isFullscreen={isFullscreen}
                      videoSrc={getVideoSrc(url)}
                      onVideoLanguageChange = {handleVideoLanguageChange}
                    />
                  </Center>
                ))}
              </Flex>
            </TabPanel>
            <TabPanel w='full' h='full' maxH='full'>
              <HStack
                w='full'
                h='100%'
                minH={0}
                align='center'
                spacing='5'
                justify='center'
              >
                {task.answerOptions.map(
                  ao =>
                    ao.type === TaskType.ImageSelection && (
                      <VStack
                        align='center'
                        justify='center'
                        h={{ base: 'auto', lg: '50%' }}
                        w='full'
                      >
                        <Center
                          key={ao.id}
                          w='full'
                          h='full'
                          pos='relative'
                          bgColor='white'
                          borderRadius='xl'
                          borderWidth='4px'
                          borderColor={ao.checked ? 'pink.500' : 'transparent'}
                          onClick={() => onSelectedChange(ao.id) }
                          className={
                            ao.checked ? 'image image-clicked' : 'image-unselected'
                          }
                        >
                          {ao.checked ? (
                            <Center
                              pos='absolute'
                              left='1'
                              top='1'
                              boxSize='5'
                              bgColor='pink.500'
                              borderRadius='full'
                            >
                              <CheckIcon color='white' />
                            </Center>
                          ) : (
                            <Center
                              pos='absolute'
                              left='1'
                              top='1'
                              boxSize='5'
                              bgColor='lightgray'
                              borderRadius='full'
                            >
                              <CheckIcon color='white' />
                            </Center>
                          )}
                          <Image
                            src={apiBase + ao.imageUrl}
                            borderRadius='xl'
                            maxH='100%'
                            w='100%'
                            h='100%'
                            fit='contain'
                            maxW='100%'
                          />
                        </Center>
                      </VStack>
                    ),
                )}
              </HStack>
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>

      <TaskAdvice
        pb='0'
        pos='relative'
        minH='unset'
        bottom='-2'
        instructions={task.taskInstructions}
      />
    </VStack>
  )
}
