import React, { useEffect } from 'react'
import './App.scss'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Authenticated from 'pages/Authenticated/Authenticated'
import Unauthenticated from 'pages/Unauthenticated/Unauthenticated'
import { useUser } from 'providers/UserProvider'
import { FollowUpProvider } from 'providers/FollowUpProvider'
import { Box } from '@chakra-ui/layout'
import { PreschoolProvider } from 'providers/PreschoolProvider'
import { useLocation } from 'react-router'
import { useGA4React } from 'ga-4-react'


function App() {
  const { user } = useUser()

  const location = useLocation()
  const analytics = useGA4React()
  useEffect(() => {
    if (analytics) {
      analytics.pageview(location.pathname)
    }
  }, [location, analytics])


  /* return <CustomComponents /> */
  return (
    <Box bgColor='darkIndigo' h='full' w='full' id='app-wrapper-id'>
    
      {user.email ? (
        <FollowUpProvider>
          <PreschoolProvider>
            <Authenticated />
          </PreschoolProvider>
        </FollowUpProvider>
      ) : (
        <Unauthenticated />
      )}
    </Box>
  )
}

export default App
