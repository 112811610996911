import React from 'react'

interface CommentIconProps {
  color?: string
  size?: string | number
}

export const CommentIcon = ({
  color = '#2A0F74',
  size = '24px',
}: CommentIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    color={color}
    fill={'none'}
  >
    <path
      d='M8 12H8.01M12 12H12.01M16 12H16.01M21 12C21 16.418 16.97 20 12 20C10.5286 20.005 9.07479 19.6808 7.745 19.051L3 20L4.395 16.28C3.512 15.042 3 13.574 3 12C3 7.582 7.03 4 12 4C16.97 4 21 7.582 21 12Z'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
