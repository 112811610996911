import React from 'react'
import Navbar from '../Navbar'
import { SkipTask } from './components'
import { HStack } from '@chakra-ui/layout'
import { useLocation } from 'react-router-dom'
import NavButtons from '../FollowUp/TaskTypes/NavButtons'

export const Footer = () => {
  const { pathname } = useLocation()

  const pathsDisplayingFooter = ['/tasks']

  return (
    <HStack minH='50px' w='full' py='4' justify='space-between' flexDirection={{ base:'column', md:'column', lg:'row' }}>
      {pathsDisplayingFooter.includes(pathname) && (
        <>
          <SkipTask />
          <HStack spacing='14' pr='14'>
            <Navbar />
            <NavButtons />
          </HStack>
        </>
      )}
    </HStack>
  )
}
