import React, { CSSProperties } from 'react'
import { Flex } from '@chakra-ui/layout'
import { IconButton } from '@chakra-ui/button'
import { useFullscreen } from 'providers/FullscreenProvider'
import { BiExpand, BiCollapse } from 'react-icons/bi'

const expandedStyle: CSSProperties = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: 'absolute',
}
const collapsedStyle: CSSProperties = {
  borderRadius: '16px',
  position: 'relative',
}

export default function ExpandablePageContainer(props: {
  showFullscreenToggle?: boolean
  children: React.ReactNode
}) {
  const { isFullscreen, setIsFullscreen } = useFullscreen()

  function toggleFullscreen() {
    setIsFullscreen(!isFullscreen)
  }

  return (
    <Flex
      h='full'
      w='full'
      position='relative'
      bgGradient='linear(to-b, purplishBlue, blue)'
      style={isFullscreen ? expandedStyle : collapsedStyle}
    >
      {props.showFullscreenToggle ? (
        <>
          {/* <IconButton
            variant='ghost'
            aria-label='fullscreen-btn'
            icon={
              isFullscreen ? (
                <BiCollapse fontSize='25px' />
              ) : (
                <BiExpand fontSize='25px' />
              )
            }
            onClick={toggleFullscreen}
            position='absolute'
            top='5'
            right='5'
            zIndex='1'
          /> */}
          {props.children}
        </>
      ) : (
        props.children
      )}
    </Flex>
  )
}
