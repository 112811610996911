import React from 'react'
import {
    Modal,
    Text,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    VStack,
    Checkbox
  } from '@chakra-ui/react'
import { t } from 'i18next'
import { ArrowForwardIcon } from '@chakra-ui/icons'

  interface InstructionProps{
    isOpen:boolean,
    onClose: ()=>void
  }
const InstructionModal = ({ isOpen, onClose }:InstructionProps) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent backgroundColor='#27106F'  className='instruction-modal-wrapper'  w="700px" maxW='700px' p='25px 15px'>
      <ModalBody w='full'>
       <VStack alignItems='flex-start' pb={'25px'}>
        <Text fontWeight='bold'>{t('Read_Before_Completing_Text')}</Text>
        <Text style={{marginTop:0}}>{t('Welcome_Instructions')}</Text>
       </VStack>
       <VStack alignItems='flex-start' pb={'25px'}>
        <Text fontWeight='bold'>{`${t('Child_Not_Participate')}:`}</Text>
        <Text style={{marginTop:0}}>{t('helpToolTipText')}</Text>
       </VStack>
       <VStack alignItems='flex-start' pb={'25px'}>
        <Text fontWeight='bold'>{t('Child_Not_Responding_Title')}</Text>
        <Text style={{marginTop:0}}>{t('Child_Not_Responding_Explanation')}</Text>
       </VStack>
       <VStack alignItems='flex-start'>
        <Text fontWeight='bold'>{t('Indicates_Feeling_Title')}</Text>
        <Text style={{marginTop:0}}>{t('Indicates_Feeling_Explanation')}</Text>
       </VStack>
      </ModalBody>

      <ModalFooter justifyContent='flex-end'>
      {/* <Checkbox className='checkbox-instruction-text'>{t('Instruction_Checkbox')}</Checkbox> */}
        <Button colorScheme='blue' mr={3} fontSize='16px' fontWeight='400' onClick={onClose} rightIcon={<ArrowForwardIcon boxSize='5' />}> 
          {t('Instruction_Understand')} 
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
  )
}

export default InstructionModal
