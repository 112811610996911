import React from 'react'
import { Image, Text, Center, Flex } from '@chakra-ui/react'
import myranLogo from 'assets/images/Logo-InDarkBG.svg'
import HamburgerMenu from 'common/HamburgerMenu'
import { useTranslation } from 'react-i18next'
export default function FollowUpForbidden() {
  const { t } = useTranslation()
  return (
    <Center h='full' p='20'>
      <Flex pos={'absolute'} top='20px' left='20px'>
        <HamburgerMenu />
      </Flex>
      <Flex
        direction='column'
        align='center'
        fontFamily='Catamaran'
        fontWeight='light'
      >
        <Image src={myranLogo} />

        <Text fontSize='2xl' mt='2' mb='7' align='center'>
          {t('No_Available_FollowUp')}
        </Text>
        <Text fontSize='small' mt='2' align='center'>
          {t(
            'Try_Later_Text',
          )}
        </Text>
      </Flex>
    </Center>
  )
}
