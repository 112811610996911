
import React, { useEffect, useRef, useState } from 'react'
import {
  Flex,
  Box,
  Image,
  VStack,
  Text,
  HStack
} from '@chakra-ui/react'
import { TaskHeading } from '../Common/TaskHeading'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { LanguageAnalysisTask } from 'data-models'
import { apiBase } from 'services/config'
import { TaskAdvice } from '../Common'
import { t } from 'i18next'
import Waves from './WavesAnimation/Waves'
import useRecording from './WavesAnimation/useRecording'
import './LanguageTask.css'
import { CheckIcon } from '@chakra-ui/icons'
import AudioPlayerLanguage from '../LanguageAnalysis/AudioPlayer/audioPlayer'
import RecordImage from './RecordComponent/RecordImage'
import { audioProgressBar, pauseAudio, playAudio, startRecording, stopRecording} from './Utility/LanguageAnalysisUtils' 
import MicrophoneOff from '../../../../../assets/images/microphone-off-01.svg'


export default function LanguageTask() {
  const [isRecording, setIsRecording] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [audioURL, setAudioURL] = useState<string | null>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const audioStreamRef = useRef<MediaStream | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const chunksRef = useRef<Blob[]>([])
  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as LanguageAnalysisTask
  const [timer, setTimer] = useState({ minutes: 3, seconds: 0 })
  const { isRecording1, handleRecord1, handleStop1 } = useRecording()
  const [totalDuration, setTotalDuration] = useState(0)
  const [audioProgress, setAudioProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isStopped, setIsStopped] = useState(false)
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isMicBlocked,setIsMicBlocked] = useState(false)
  const imageUrl = apiBase + task.imageUrl;
  const [languageSelected, setLanguageSelected] = useState(sessionStorage.getItem('language-selected'))
  let startTime
    let stopTime
  const [showOverlay, setShowOverlay] = useState(false); // State for overlay visibility
  const [stopButtonPosition, setStopButtonPosition] = useState({ x: 0, y: 0 }); // State for stop button position
  

  useEffect(() => {
   
    if (task.isSkipped) {
      //   task.audioRecordings = []

      const audioObject = {
        audioFile: '',
        audioLength: 0,
        taskId: task.id,
        isSkipped: true,
        skipReason : sessionStorage.getItem(task.id+'-language') ?? undefined
      }

      task.audioRecordings.push(audioObject)
    }
  }, [task.isSkipped])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (isRecording) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer.minutes === 0 && prevTimer.seconds === 0) {
            clearInterval(interval)
            handleStopClick()
            return prevTimer
          } else if (prevTimer.seconds === 0) {
            return { minutes: prevTimer.minutes - 1, seconds: 59 }
          } else {
            return {
              minutes: prevTimer.minutes,
              seconds: prevTimer.seconds - 1,
            }
          }
        })
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [isRecording])


  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${String(seconds).padStart(2, '0')}`
  }


  
  const handleRecordClick = async () => {
    try {
      // Request access to the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      // Start recording if access is granted
      setIsMicBlocked(false)
      setShowOverlay(true);
      const { x, y } = document.getElementById('stop-button')?.getBoundingClientRect() || { x: 0, y: 0 };
      setStopButtonPosition({ x, y });
  
      startRecording(
        task,
        chunksRef,
        audioStreamRef,
        mediaRecorderRef,
        setIsRecording,
        setIsSaving,
        setIsSaved,
        setAudioURL,
        setTotalDuration,
        setTimer,
        dispatch,
        handleStop1,
        handleRecord1,
        setIsStopped,
        startTime,
        stopTime,
        QUESTION_ACTIONS
      );
    } catch (error) {
      // Handle the case where microphone access is denied or blocked
      console.error('Microphone access denied:', error);
      // You can show a message to the user or handle the error in an appropriate way
      setIsMicBlocked(true)
    }
  };
  

const handleStopClick = async() => {
  stopRecording(
    handleStop1,
    mediaRecorderRef,
    audioStreamRef,
    setIsRecording,
    setIsSaving,
    setTimer,
    dispatch,
    QUESTION_ACTIONS,
    setIsSaved
  )

   // Hide the overlay when recording stops
   setShowOverlay(false);
}


const handlePlayClick = () => {
  playAudio(
    setIsPlaying,
    audioURL,
    audioRef,
    setAudioProgress,
    requestAnimationFrame
  );
};

  const handleAudioProgress = () => {
   audioProgressBar(
    audioRef, 
    setAudioProgress
   )
  }

  const handlePauseClick = () => {
    pauseAudio(
      setIsPlaying,
      audioURL,
      audioRef
    )
  }

  return (
    <Flex direction='column' h='full' w='full'>
      <VStack p='5' pb='4' w='full' h='full' align='center'>
      {showOverlay && (
          <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="rgba(0, 0, 0, 0.7)"
            zIndex="997"
          />
        )}
        <TaskHeading
          description={task.description}
          subtitle={task.subtitle}
          headingTitle={task.title}
        />
        <HStack w='full' h='80%' flexDirection={{base:'column',lg:'row'}}>
          <Box flex='70%' display='flex' justifyContent='center' h='full'>
            <Image
              borderRadius={'8px'}
              px='2'
              py='2'
              h='full'
              src={apiBase + task.imageUrl}
              alt='task_image'
              overflow='auto'
          //    cursor='-webkit-zoom-in'
           //   onClick={()=> setIsImageOpen(true)}
            />
          </Box>
         
          <Flex align='center' flexDirection='column' flex='30%' h='300px' zIndex='1000' w='full' position='relative'>
          {isMicBlocked && 
              <Box display='flex' width='80%' margin='0 auto' position='absolute' top={'-28px'}>
                <Image src={MicrophoneOff} mr={'14px'} />
              <Text className='audio-block-error' fontSize={'16px'} lineHeight='1.2' mb={0}>{t('Audio_Allow_Access')}</Text>
              </Box>}
            <Box >
           
              <Text display='flex' alignItems='center' h='40px'>
                {isRecording ? (
                  t('Recording_Text')
                ) : isStopped ? (
                  <>
                    <Box
                      outline='1px solid white'
                      borderRadius='50%'
                      h='30px'
                      w='30px'
                      display='flex'
                      alignItems='center'
                      marginRight='10px'
                    >
                      <CheckIcon ml='2' color='white' />
                    </Box>
                    {t('Stopped_Text')}
                  </>
                ) : (
                  ''
                )}
              </Text>
            </Box>

            <VStack>
              <Box
                mb={4}
                ml={6}
                mt={4}
                display='flex'
                flexDirection='column'
                alignItems='center'
                width='100%'
              >
                <Box>
                  <RecordImage
                    isRecording={isRecording}
                    handleStopClick={handleStopClick}
                    handleRecordClick={handleRecordClick}
                  />
                </Box>
                <Text mb={4}>
                  {isRecording
                    ? t('Stop')
                    : isStopped
                    ? t('Retake')
                    : t('Record')}
                </Text>
              </Box>
            </VStack>
            {!isStopped ? (
              <VStack ml={6}>
                <Box  mb={4}>
                  {isRecording && <Waves />}
                </Box>
                <Box ml={6} mb={4}>
                  {isRecording && (
                    <Text>{`${timer.minutes
                      .toString()
                      .padStart(2, '0')}:${timer.seconds
                      .toString()
                      .padStart(2, '0')}`}</Text>
                  )}
                </Box>
              </VStack>
            ) : (
              <AudioPlayerLanguage
                isPlaying={isPlaying}
                handlePauseClick={handlePauseClick}
                handlePlayClick={handlePlayClick}
                audioProgress={audioProgress}
                audioRef={audioRef}
                formatTime={formatTime}
                audioURL={audioURL}
                handleAudioProgress={handleAudioProgress}
              />
            )}
          </Flex>
        </HStack>
        <TaskAdvice
          instructions={task.taskInstructions}
          padding='0'
          justifyContent='end'
          minHeight='8%'
        />
      </VStack>
    </Flex>
  )
}


