import { Preschool } from 'data-models/preschool'
import axiosAPIInstance from './api'
import {cookies} from './cookies'

const PreschoolService = {
  getPreschools: (organizationId: string) => {
    return axiosAPIInstance
      .get(`/preschools/${organizationId}`)
      .then(r => r.data)
  },

  cachePreschool: (preschool: Preschool) =>
    cookies.set('preschool', JSON.stringify(preschool)),

  getCachedPreschool: () =>
    JSON.parse(cookies.get('preschool') || '{}') as Preschool,
}

export { PreschoolService }
