import React, { useEffect, useState } from 'react'
import { AudioTask, CheckboxAnswer } from 'data-models/task-models'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { useFullscreen } from 'providers/FullscreenProvider'
import TaskTag from '../../TaskTag'
import {
  Flex,
  Text,
  Center,
  Button,
  VStack,
  HStack,
  Image,
  Box,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import infoImage from 'assets/images/information-circle.svg'
import CheckboxAnswerGroup from 'common/CheckboxAnswerGroup/CheckboxAnswerGroup'
import AudioPlayer from './AudioPlayer'
import NavButtons from '../NavButtons'
import { apiBase } from 'services/config'

export default function AudioTaskCmp() {
  const { isFullscreen } = useFullscreen()
  const [isShowingTask, setIsShowingTask] = useState(true)

  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as AudioTask

  useEffect(() => {
    setIsShowingTask(true)
  }, [currentTask.id])

  const showAnswers = () => setIsShowingTask(false)
  const showTask = () => setIsShowingTask(true)

  const onAnswerChange = (answers: CheckboxAnswer[]) => {
    dispatch({ type: QUESTION_ACTIONS.UPDATE_ANSWERS, payload: answers })
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }

  return isShowingTask ? (
    <Flex direction='column' alignItems='start'>
      <TaskTag>{task.description}</TaskTag>
      <Text fontSize='2xl' fontWeight='semibold' mt='3'>
        {task.title}
      </Text>
      <Text fontWeight='semibold' mt='3'>
        {task.subtitle}
      </Text>
      <Center mt='8' w='full'>
        <Center
          w='700px'
          h='290px'
          border='1px solid #432DBA'
          borderRadius='2xl'
        >
          <AudioPlayer audioSrc={apiBase + task.audioUrl} />
        </Center>
      </Center>
      <VStack
        position='absolute'
        left='0'
        right='0'
        bottom='0'
        height={isFullscreen ? '140px' : '80px'}
      >
        <Box
          my='3'
          bgColor='#352EB1'
          position='absolute'
          left='0'
          right='0'
          top='0'
          height='1px'
        />
        <HStack alignSelf='flex-start' px='6' pt='4' lineHeight='1.5'>
          <Image src={infoImage} />
          <Text fontSize='sm'>{task.taskInstructions}</Text>
        </HStack>
      </VStack>
      <Button
        rightIcon={<ChevronUpIcon boxSize='8' />}
        alignSelf='center'
        position='absolute'
        bottom={isFullscreen ? '35px' : '-25px'}
        onClick={showAnswers}
        zIndex='2'
      >
        Markera svar
      </Button>
    </Flex>
  ) : (
    <Flex direction='column' pt={isFullscreen ? '60px' : '0'} h='full'>
      <Button
        rightIcon={<ChevronDownIcon boxSize='8' />}
        alignSelf='center'
        position='absolute'
        top={isFullscreen ? '20px' : '-25px'}
        onClick={showTask}
        zIndex='2'
        _hover={{ background: '#EF3A86' }}
        _selected={{ background: '#DC1F6E' }}
      >
        Dölj
      </Button>
      <VStack
        position='absolute'
        left='0'
        right='0'
        top='0'
        height={isFullscreen ? '120px' : '80px'}
      >
        <HStack alignSelf='flex-start' px='6' pt={isFullscreen ? '20' : '10'}>
          <Image src={infoImage} />
          <Text fontSize='sm'>{task.answerInstructions}</Text>
        </HStack>
        <Box
          my='3'
          bgColor='#352EB1'
          position='absolute'
          left='0'
          right='0'
          bottom='0'
          height='1px'
        />
      </VStack>
      <Flex direction='column' h='full' w='full' align='center'>
        <Center flex='1' w='60%'>
          <CheckboxAnswerGroup
            options={task.answerOptions}
            onChange={onAnswerChange}
          />
        </Center>

        <NavButtons />
      </Flex>
    </Flex>
  )
}
