import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Text,
  Flex,
  Slider,
  IconButton,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  VStack,
  HStack,
  useMediaQuery,
  Image
} from '@chakra-ui/react'
import { useVideo } from 'react-use'
import { isIOS } from 'react-device-detect'
import { secondsToMMSS } from 'common/utils'
import {
  BsVolumeUpFill,
  BsVolumeOffFill,
  BsVolumeDownFill,
  BsVolumeMuteFill,
  BsFullscreen,
} from 'react-icons/bs'
import { FaPlay, FaPause } from 'react-icons/fa'
import VideoLanguage from './VideoLanguage'


interface VideoPlayerProps {
  videoSrc: string
  isFullscreen: boolean
  onVideoLanguageChange : (lang: any) => void
}

export default function VideoPlayer({ videoSrc,isFullscreen,onVideoLanguageChange }: VideoPlayerProps) {
  const [isSmallScreen] = useMediaQuery('(max-height: 900px)')
  const videoRef = useRef<HTMLVideoElement>(null);
  

  const handlePlayClick = () => {
    const videoElement = document.getElementById('video-task-id') as HTMLVideoElement;
   
    if (videoElement) {
      // Check if the document is not already in fullscreen mode
      // if (!document.fullscreenElement) {
        // Request fullscreen for the video element
        if ((videoElement as any).webkitRequestFullscreen) { /* Safari */
          (videoElement as any).webkitRequestFullscreen();
        } 
       else if (videoElement.requestFullscreen) {
         
          videoElement.requestFullscreen();
        
        } else  if ((videoElement as any).mozRequestFullScreen) { /* Firefox */
          (videoElement as any).mozRequestFullScreen();
        } else if ((videoElement as any).msRequestFullscreen) {/* IE/Edge */
          (videoElement as any).msRequestFullscreen();
        }
      // }
    }
      // Start playing the video
      if(videoElement.paused){
       
        setTimeout(() => {
          play();
        }, 50);
      }
     
   
  };
 
  const closeVideo = () => {
    const videoElement = document.getElementById('video-task-id') as HTMLVideoElement;
  
    if (videoElement) {
      if ((videoElement as any).webkitExitFullscreen) { /* Safari */
        (videoElement as any).webkitExitFullscreen();
      } else if ((videoElement as any).mozCancelFullScreen) { /* Firefox */
        (videoElement as any).mozCancelFullScreen();
      } else if ((videoElement as any).msExitFullscreen) { /* IE/Edge */
        (videoElement as any).msExitFullscreen();
      }
    }
  }
  
  
  const [
    video,
    { duration, playing, time, volume },
    { pause, play, seek, volume: setVolume },
  ] = useVideo(
    <video
      src={videoSrc}
      ref={videoRef}
      id='video-task-id'
      onClick={handlePlayClick}
      onEnded={() => {
        closeVideo(); // Add this line to close fullscreen when the video ends
      }}
      style={{
        width: '100%',
        height: isSmallScreen ? '30vh' : '50vh',
        objectFit: 'contain',
        borderRadius: '32px',
      }}
    />,
  )





  let isSeeking = false
  const onScrub = (v: number) => {
    if (playing) {
      pause()
      isSeeking = true
      seek(v)
      isSeeking = false
      play()
    } else seek(v)
  }

  const volumeIcon =
    volume > 0.8 ? (
      <BsVolumeUpFill fontSize='30px' />
    ) : volume > 0.4 ? (
      <BsVolumeDownFill fontSize='30px' />
    ) : volume > 0 ? (
      <BsVolumeOffFill fontSize='30px' />
    ) : (
      <BsVolumeMuteFill fontSize='30px' />
    )


    const handleLanguageChange = (lang: any) => {
      pause(); // Pause the video when the language changes
      setTimeout(()=>{
        onVideoLanguageChange(lang); // Call the passed in callback

      },200)
    };

  const toggleVolume = () => (volume ? setVolume(0) : setVolume(1))

    
  return (
    <VStack
      w='full'
      p='2'
      pb='3'
      gridGap='2'
      align='center'
      direction='column'
      borderRadius='2xl'
      border='1px solid #432DBA'
      maxW={isSmallScreen ? '75vw' : '45vw'}
    >
      <Flex w='full' h='full'>
        {video}
      </Flex>

      <HStack w='full' h='full' align='center' px='1'>
        <Flex>
          {playing ? (
            <IconButton
              aria-label='pause-btn'
              icon={<FaPause />}
              onClick={pause}
              boxSize='40px'
              borderRadius='full'
            />
          ) : (
            <IconButton
              aria-label='play-btn'
              id="video-play-btn"
              icon={<FaPlay />}
              onClick={handlePlayClick}
              boxSize='40px'
              borderRadius='full'
            />
          )}
        </Flex>

        <Text
          w='90px'
          fontSize='small'
          fontFamily='Open Sans'
          whiteSpace='nowrap'
          ml='16px'
          mr='8px'
        >
          {secondsToMMSS(time)} / {secondsToMMSS(duration)}
        </Text>
        
        <Slider
          aria-label='track-progress'
          min={0}
          max={duration}
          onChange={onScrub}
          flex='1'
          {...(!isSeeking && { value: time })}
        >
          <SliderTrack bg='darkIndigo' h='1.5' borderRadius='md'>
            <SliderFilledTrack bg='pink.500' />
          </SliderTrack>
          <SliderThumb boxSize='7' boxShadow='lg'>
            <Box borderRadius='full' bgColor='pink.500' boxSize='3'></Box>
          </SliderThumb>
        </Slider>
        {!isIOS && (
          <Slider
            aria-label='volume'
            value={volume}
            min={0}
            max={1}
            step={0.1}
            focusThumbOnChange={false}
            onChange={setVolume}
            w='15%'
            left='6'
          >
            <SliderTrack bg='darkIndigo' h='1.5' borderRadius='md'>
              <SliderFilledTrack bg='pink.500' />
            </SliderTrack>
            <SliderThumb boxSize='7' boxShadow='lg'>
              <Box borderRadius='full' bgColor='pink.500' boxSize='3'></Box>
            </SliderThumb>
          </Slider>
        )}
        {!isIOS && (
          <Box onClick={toggleVolume} cursor='pointer' pl='10'>
            {volumeIcon}
          </Box>
        )}

        <Box className='video-language-selector'>
         <VideoLanguage onVideoLanguageChange={handleLanguageChange} />
        </Box>
      </HStack>
    </VStack>
  )
}
