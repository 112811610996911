import React from 'react'
import { ImagePlacementTask } from 'data-models/task-models'
import ImagePlacementItem from './ImagePlacementItem'
import { Center, SimpleGrid, useMediaQuery } from '@chakra-ui/react'
import { useFollowUp } from 'providers/FollowUpProvider'

export default function ImagePlacementItems() {
  const { currentTask } = useFollowUp()
  const task = currentTask as ImagePlacementTask

  const [isSmallScreen] = useMediaQuery('(max-height: 900px)')

  return (
    <SimpleGrid columns={3} pr='20' spacing='10px'>
      {task.answerOptions
        .filter(ao => !ao.placedAtPoint)
        .map(ao => (
          <Center
            key={ao.imageUrl}
            bgColor='white'
            boxSize={isSmallScreen ? '90px' : '100px'}
            borderRadius='2xl'
            mr='10'
          >
            <ImagePlacementItem ipa={ao} />
          </Center>
        ))}
    </SimpleGrid>
  )
}
