import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    pink: {
      DEFAULT: '#FF5099',
      50: '#ff1041',
      100: '#fff0f6',
      200: '#FFE9F2',
      300: '#FFB6D4',
      400: '#FF83B7',
      500: '#FF5099',
      600: '#FF1D7B',
      700: '#E90061',
      800: '#B6004C',
      900: '#830037',
    },
    mainBlue: {
      50: '#dee4f2',
      500: '#277aff',
      700: '#41567e',
      900: '#2a244d',
    },
    indigo: '#211761',
    darkIndigo: '#2a0f74',
    blue: '#3343d4',
    purplishBlue: '#773ffd',
    error: '#db2269',
    borderGrey: '#ECEAF0',
  },

  styles: {
    global: {
      body: {
        fontFamily: 'Open Sans',
        lineHeight: '1',
      },
    },
  },

  components: {
    Tooltip: {
      baseStyle: {
        zIndex: 0,
      },
    },

    Input: {
      defaultProps: {
        focusBorderColor: 'white',
      },
      variants: {
        outline: {
          field: {
            h: '60px',
            bgColor: 'indigo',
            borderColor: 'transparent',
            color: 'white',
            borderRadius: 'xl',
          },
        },
      },
    },

    Textarea: {
      baseStyle: {
        minHeight: '120px',
      },

      variants: {
        outline: {
          bgColor: 'indigo',
          borderColor: 'transparent',
          color: 'white',
          borderRadius: 'xl',
        },
      },
    },

    Button: {
      baseStyle: {
        color: 'white',
        borderRadius: '40px',
        fontSize: 'xs',
      },
      variants: {
        solid: {
          bg: 'pink.500',
          boxShadow: 'md',
          fontSize: '18px',
          fontWeight: 'semibold',
          px: '15px',
          h: '35px',
          _hover: {
            bg: '#EF3A86',
            _disabled: {
              bg: 'pink.200',
            },
          },
          _active: {
            bg: '#DC1F6E',
          },
        },
        outline: {
          borderColor: 'white',
          color: 'white',
          fontWeight: 'normal',
          bg: 'blue',
          px: '15px',
          h: '35px',
          _hover: {
            bg: 'blue',
            opacity: 0.8,
            _disabled: {
              borderColor: 'whiteAlpha.100',
            },
          },
          _active: {
            bg: 'whiteAlpha.500',
            borderColor: 'whiteAlpha.800',
          },
        },
        confirmation: {
          bg: 'pink.500',
          boxShadow: 'md',
          fontSize: '20px',
          fontWeight: 'semibold',
          h: '60px',
          px: '20px',
          _focus: { boxShadow: 'brandPrimary.400' },
          _hover: {
            bg: '#EF3A86',
            _disabled: {
              bg: 'pink.200',
            },
          },
          _active: {
            bg: '#DC1F6E',
          },
        },
        outline2: {
          border: '1px solid',
          borderColor: 'pink.500',
          borderRadius: '8px',
          color: 'white',
          fontWeight: '700',
          fontSize: 'sm',
          // bg: 'purplishBlue',
          px: '4',
          py: '2',
          _hover: {
            bg: 'pink.500',
            _disabled: {
              borderColor: 'whiteAlpha.100',
            },
          },
          _active: {
            bg: 'whiteAlpha.500',
            borderColor: 'whiteAlpha.800',
          },
        },
        ghost: {
          color: 'white',
          fontWeight: 'light',
          _hover: {
            opacity: 0.8,
            // bg: 'whiteAlpha.100',
          },
        },
      },
    },

    Tabs: {
      baseStyle: {
        tabpanel: {
          p: 0,
        },
      },
      variants: {
        line: {
          tab: {
            _selected: {
              fontWeight: 'bold',
              color: '#2a244d',
              borderColor: 'mainBlue.500',
              borderBottomWidth: '4px',
            },
          },
        },
      },
    },

    Select: {
      baseStyle: {
        field: {
          bgColor: 'darkIndigo',
        },
      },
      variants: {
        filled: {
          field: {
            borderRadius: 'lg',
            _hover: {
              bgColor: 'darkIndigo',
              _disabled: {
                bgColor: 'pink.200',
              },
            },
            _focusVisible: {
              bgColor: 'darkIndigo',
            },
          },
        },
      },
    },

    Heading: {
      baseStyle: {
        fontFamily: 'Open Sans',
      },
    },

    Modal: {
      baseStyle: {
        overflow: 'unset',
      },
    },

    Checkbox: {
      baseStyle: {
        label: {
          color: 'white',
        },
        control: {
          borderColor: 'pink.DEFAULT',
          _checked: {
            backgroundColor: 'pink.DEFAULT',
          },
          _focus: {
            boxShadow: 'none',
          },
        },
        icon: {
          color: 'white',
        },
      },
    },

    IconButton: {
      baseStyle: {
        color: 'red',
        backgroundColor: 'red',
      },
      variants: {
        ghost2: {
          color: 'red',
          backgroundColor: 'red',
          fontWeight: 'light',
          _focus: {
            boxShadow: 'none',
          },
          _hover: {
            bg: 'white',
            color: 'black',
          },
        },
      },
    },
  },
})
