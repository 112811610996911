import { useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { UnregisterCallback } from 'history'

interface UseBlockNavigationProps {
  onBlockCallback: () => void
  exemptPaths: string[]
}

export const useBlockNavigation = ({
  onBlockCallback,
  exemptPaths = [],
}: UseBlockNavigationProps): (() => void) => {
  const blockedPathname = useRef<string>()
  const unblock = useRef<UnregisterCallback>()
  const history = useHistory()

  useEffect(() => {
    unblock.current = history.block(({ pathname }) => {
      if (!exemptPaths.includes(pathname)) {
        onBlockCallback()
        blockedPathname.current = pathname
        return false
      }
    })
    return () => {
      unblock.current && unblock.current()
    }
  }, [history, onBlockCallback])

  const leavePage = () => {
    if (unblock && unblock.current) {
      unblock.current()
    }
    history.push(blockedPathname.current!)
  }

  return leavePage
}
