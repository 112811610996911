import React from 'react'
import { Image, Text, Center, Flex } from '@chakra-ui/react'
import myranLogo from 'assets/images/Logo-InDarkBG.svg'
import { useTranslation } from 'react-i18next'
export default function NoFollowUp() {
  const { t } = useTranslation()
  return (
    <Center h='full'>
      <Flex
        direction='column'
        align='center'
        fontFamily='Catamaran'
        fontWeight='light'
      >
        <Image src={myranLogo} />

        <Text fontSize='2xl' mt='2' mb='7'>
          {t('No_FollowUp_Text')}
        </Text>

        <Text fontSize='small'>{t('Next_Followup_Date')}</Text>
      </Flex>
    </Center>
  )
}
