import React, { useState, useEffect } from 'react';
import { Image } from '@chakra-ui/react';
import fullscreenIcon from '../../../../assets/images/Fullscreen-Icon.svg';
import minimiseIcon from '../../../../assets/images/Minimise-Icon.svg';
import './styles/Fullscreen.styles.scss';

const FullScreenMode = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const handleFullScreen = () => {
      if (!document.fullscreenElement) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
          setIsFullScreen(true)
        } else if ((document.documentElement as any).webkitRequestFullscreen) { /* Safari */
        
          (document.documentElement as any).webkitRequestFullscreen();
          setIsFullScreen(true)

        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setIsFullScreen(false)
        } else if ((document as any).webkitExitFullscreen) { /* Safari */
          (document as any).webkitExitFullscreen();
          setIsFullScreen(false)
        }
      }
    };
    

    return (
        <div className={`fullscreen-wrapper ${isFullScreen ? 'fullscreen' : ''}`}>
            <Image src={isFullScreen ? minimiseIcon : fullscreenIcon} className={`${isFullScreen ? 'minimise-image' : 'fullscreen-image'}`} onClick={handleFullScreen} />
        </div>
    );
};

export default FullScreenMode;
