import React, { PropsWithChildren } from 'react'
import questionMarkImage from 'assets/images/question-mark.svg'
import {
  Heading,
  Tooltip,
  VStack,
  Text,
  Image,
  FlexProps,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
export const helpToolTipText =
  'Barnet finns i rummet tillsammans med dig, men är inte aktiv i undersökningen. (e.x. tittar bort eller lämnar platsen)'

interface HelpToolTipProps extends FlexProps {
  title?: string
  body?: string
  image?: React.ReactNode
  imgSize?: number
}

export const HelpToolTip = ({
  title,
  body,
  image,
  imgSize = 5,
  children,
  ...rest
}: PropsWithChildren<HelpToolTipProps>) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      placement='top'
      hasArrow
      bg='white'
      mt='1'
      p='4'
      borderRadius='lg'
      boxShadow='lg'
      shouldWrapChildren
      label={
        children ? (
          children
        ) : (
          <VStack align='baseline'>
            <Heading fontSize={'md'} color='black'>
              {title ? title : `${t('Child_Not_Participate')}:`}
            </Heading>
            <Text
              fontSize={'sm'}
              color='black'
              fontWeight='light'
              lineHeight='normal'
            >
              {body ? body : `${t('helpToolTipText')}`}
            </Text>
          </VStack>
        )
      }
    >
      <Flex {...rest}>
        {image ? image : <Image boxSize={imgSize} src={questionMarkImage} />}
      </Flex>
    </Tooltip>
  )
}
