import React from 'react'

interface UserManualIconProps {
  color?: string
  size?: string | number
}

export const UserManualIcon = ({
  color = 'black',
  size = '24px',
}: UserManualIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    color={color}
    fill='none'
  >
    <path
      d='M8.40008 7.20039H15.6001M8.40008 10.8004H15.6001M8.40008 14.4004H12.0001M6.5998 2.40039H17.4C18.7255 2.40039 19.8 3.47493 19.8 4.80044L19.7998 19.2004C19.7997 20.5259 18.7252 21.6004 17.3997 21.6004L6.5997 21.6003C5.27421 21.6003 4.1997 20.5258 4.19971 19.2003L4.1998 4.80038C4.19981 3.4749 5.27432 2.40039 6.5998 2.40039Z'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
