import { Circle,Image } from '@chakra-ui/react'
import React from 'react'
import recordIcon from '../../../../../../assets/images/Record-Icon.svg'
import stopIcon from '../../../../../../assets/images/Stop-Icon.svg'

interface RecordImageProps {
    isRecording: boolean,
    handleStopClick: () => void,
    handleRecordClick: () => Promise<void>
}

const RecordImage = ({isRecording, handleStopClick, handleRecordClick}: RecordImageProps) => {
  return (
    <Circle
    size='100px'
    bg={!isRecording ? 'var(--barbie-pink)' : 'white'}
    mb={4}
    className={isRecording ? 'recording-animation' : ''}
    id='recordImage'
    onClick={isRecording ? handleStopClick : handleRecordClick}
    cursor='pointer'
    maxW='100px' 
    maxH='100px'
    ml={0}
  >
    <Image src={isRecording ? stopIcon : recordIcon} />
  </Circle>
  )
}

export default RecordImage
