import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import orientationImage from '../../assets/images/orientation.png'
import {  Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Image
  } from '@chakra-ui/react'

const OrientationModal = () => {
    const { i18n } = useTranslation()
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth)
    const [isIPad, setIsIPad] = useState(/iPad/.test(navigator.userAgent))
  

    useEffect(() => {
    
    

        const handleResize = () => {
            setIsPortrait(window.innerHeight > window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (!isPortrait) {
        return null; // Return null when orientation is not portrait or device is iPad
    }

      return (
        isPortrait && (
          <Modal isOpen={isPortrait} onClose={() => setIsPortrait(false)} closeOnOverlayClick={false}>
            <ModalOverlay/>
            <ModalContent alignItems="center" p={7} style={{ background: 'rgba(255, 255, 255, 0.9)' }}>
              <ModalHeader color="black" fontSize='24px' fontWeight='700'>
                {i18n.language === 'en' ? 'Please rotate your device' : i18n.language === 'se' ? 'Vänligen rotera din enhet' : 'Bitte drehen Sie Ihr Gerät'}
              </ModalHeader>
              <ModalBody>
                <Image src={orientationImage} pb={5} />
              </ModalBody>
            </ModalContent>
          </Modal>
        )
      );
}

 export default OrientationModal


// import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
// import orientationImage from '../../assets/images/orientation.png'
// import {  Modal,
//     ModalHeader,
//     ModalOverlay,
//     ModalContent,
//     ModalBody,
//     Image
//   } from '@chakra-ui/react'

// const OrientationModal = () => {
//     const { i18n } = useTranslation()
//     const [isPortrait,setIsPortrait] = useState(false)
//     const changeOrientation = () => {
//         if(screen.orientation.type.includes('portrait')){
//           setIsPortrait(true)
//         }
//         else{
//           setIsPortrait(false)
//         }
//       }
//     useEffect(() => {

//         changeOrientation();
//         screen.orientation.addEventListener("change", changeOrientation);
//       },[]); 
//       if (!isPortrait) {
//         return null; // Return null when orientation is not portrait
//       }
//       return (
//         isPortrait && (
//           <Modal isOpen={isPortrait} onClose={() => setIsPortrait(false)} closeOnOverlayClick={false}>
//             <ModalOverlay bg="blackAlpha.300" />
//             <ModalContent alignItems="center" p={7} style={{ background: 'rgba(255, 255, 255, 0.9)' }}>
//               <ModalHeader color="black" fontSize='24px' fontWeight='700'>
//                 {i18n.language === 'en' ? 'Please rotate your device' : 'Vänligen rotera din enhet'}
//               </ModalHeader>
//               <ModalBody>
//                 <Image src={orientationImage} pb={5} />
//               </ModalBody>
//             </ModalContent>
//           </Modal>
//         )
//       );
// }

// export default OrientationModal




