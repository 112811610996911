import React from 'react'

interface SchoolIconProps {
  color?: string
  size?: string | number
}

export const SchoolIcon = ({
  color = '#222222',
  size = '24px',
}: SchoolIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    color={color}
    fill={'none'}
  >
    <path
      d='M8 14V17M12 14V17M16 14V17M3 21H21M3 10H21M3 7L12 3L21 7H3ZM4 10H20V21H4V10Z'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
