import React, { PropsWithChildren } from 'react'
import { Button, Flex, FlexProps } from '@chakra-ui/react'
import { useFeedback } from './hooks'
import { FeedbackIcon } from 'common/FeedbackIcon'
import { FeedbackModal } from './components'

export const Feedback = ({ children }: PropsWithChildren<FlexProps>) => {
  const { isOpen, onClose, onOpen } = useFeedback()

  return (
    <Flex>
      <Flex onClick={onOpen} h='full' w='full'>
        {children ? (
          children
        ) : (
          <Button
            p='0'
            h='32px'
            w='32px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            variant='unstyled'
            _focus={{
              border: 'none',
              boxShadow: 'none',
            }}
            _hover={{
              color: 'black',
              opacity: 0.6,
            }}
          >
            <FeedbackIcon />
          </Button>
        )}
      </Flex>
      <FeedbackModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
