import React from 'react'
import { useDrop } from 'react-dnd'
import { ImagePlacementAnswer, PlacementPoint } from 'data-models/task-models'
import { Center } from '@chakra-ui/react'
import ImagePlacementItem from './ImagePlacementItem'

export default function ImagePlacementPoint(props: {
  pp: PlacementPoint
  pi?: ImagePlacementAnswer
}) {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'image',
    drop: () => props.pp,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const bgColor = canDrop
    ? isOver
      ? 'rgba(255,255,255,0.2)'
      : 'rgba(255, 80, 153, 0.2)'
    : 'transparent'

  const pointSizePX = 75
  const offset = pointSizePX / 2.5

  return (
    <Center
      key={props.pp.id}
      boxSize={pointSizePX + 'px'}
      bgColor={bgColor}
      pos='absolute'
      borderRadius='full'
      top={props.pp.y - offset + 'px'}
      left={props.pp.x - offset + 'px'}
      ref={drop}
    >
      {props.pi && <ImagePlacementItem ipa={props.pi} />}
    </Center>
  )
}
