import React from 'react'

export default function ReadIcon(props: {
  width?: React.SVGAttributes<SVGSVGElement>['width']
  height?: React.SVGAttributes<SVGSVGElement>['height']
  fill?: React.SVGAttributes<SVGSVGElement>['fill']
}) {
  return (
    <svg
      width={props.width ? props.width : '24px'}
      height={props.height ? props.height : '24px'}
      viewBox='0 0 24 24'
      fill={props.fill ? props.fill : 'white'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12 22.7248H19.9651C20.3211 22.7249 20.6691 22.6194 20.9652 22.4217C21.2612 22.2239 21.4919 21.9428 21.6282 21.6139C21.7645 21.285 21.8001 20.9231 21.7307 20.574C21.6612 20.2248 21.4898 19.9041 21.238 19.6523L20.5503 18.9646C22.285 17.0596 23.2476 14.5765 23.25 12C23.25 6.08642 18.2036 1.27515 12 1.27515C5.79639 1.27515 0.75 6.08642 0.75 12C0.75 17.9136 5.79639 22.7248 12 22.7248ZM12 2.77515C17.376 2.77515 21.75 6.91332 21.75 12C21.7463 13.2044 21.4987 14.3956 21.0223 15.5018C20.5458 16.608 19.8504 17.6063 18.9778 18.4365C18.9064 18.5057 18.8495 18.5885 18.8104 18.6799C18.7713 18.7714 18.7507 18.8697 18.75 18.9691C18.7493 19.0686 18.7683 19.1672 18.806 19.2592C18.8438 19.3512 18.8994 19.4348 18.9698 19.5051L20.1775 20.7129C20.2195 20.7548 20.2481 20.8083 20.2596 20.8665C20.2712 20.9247 20.2653 20.9851 20.2425 21.0399C20.2198 21.0947 20.1813 21.1416 20.1319 21.1745C20.0825 21.2074 20.0244 21.2249 19.9651 21.2248H12C6.624 21.2248 2.25 17.0867 2.25 12C2.25 6.91332 6.624 2.77515 12 2.77515Z' />
      <path d='M12 13.875C12.2984 13.875 12.5845 13.7565 12.7955 13.5455C13.0065 13.3345 13.125 13.0484 13.125 12.75V8.25C13.125 7.95163 13.0065 7.66548 12.7955 7.4545C12.5845 7.24353 12.2984 7.125 12 7.125C11.7016 7.125 11.4155 7.24353 11.2045 7.4545C10.9935 7.66548 10.875 7.95163 10.875 8.25V12.75C10.875 13.0484 10.9935 13.3345 11.2045 13.5455C11.4155 13.7565 11.7016 13.875 12 13.875Z' />
      <path d='M12 16.875C11.3787 16.875 10.875 16.3713 10.875 15.75C10.875 15.1287 11.3787 14.625 12 14.625C12.6213 14.625 13.125 15.1287 13.125 15.75C13.125 16.3713 12.6213 16.875 12 16.875Z' />
    </svg>
  )
}
